import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  buildListReducer,
  buildDetailsReducer,
  myBuildListReducer,
  buildCreateReducer,
  toApproveBuildListReducer,
  approveBuildReducer,
  buildReviewListReducer,
  reviewBuildReducer,
  filterBuildReducer,
  savedbuildsListReducer,
  buildBadgeInfoReducer,
  badgeBuildReducer,
  followBuildReducer,
  saveBuildReducer,
  buildFollowersListReducer,
  unfollowBuildReducer,
  unsaveBuildReducer,
  deleteBuildReducer,
  updateBuildReducer,
  featureBuildListReducer,
  featureBuildReducer,
  buildsBySectionReducer,
  buildFeatureCountReducer,
  followedBuildsListReducer,
} from "./reducers/buildReducers";
import { modListReducer, modCreateReducer,deleteModReducer } from "./reducers/modReducers";
import {
  allproductsByBuildReducer,
  productListReducer,
  productDetailsReducer,
  productCreateReducer,
  productsToApproveListReducer,
  approveProductReducer,
  productsToReviewListReducer,
  reviewProductReducer,
  saveProductReducer,
  unsaveProductReducer,
  savedProductsListReducer,
  savedProductsByModReducer,
  updateProductReducer,
  deleteProductReducer,
  productsToAddAFLReducer,
  addProductAFLReducer,
  createDealReducer,
  productDealsReducer,
  editDealReducer,
  updateDealReducer,
} from "./reducers/productReducers";
import {
  userLoginReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
  userRegisterReducer,
  userNotificationsReducer,
  clearUserNotificationsReducer,
  emailPreferencesReducer,
  emailPreferencesUpdateReducer,
  resetPasswordCodeRequest,
  resetPasswordRequest,
} from "./reducers/userReducers";

const reducer = combineReducers({
  buildList: buildListReducer,
  buildDetails: buildDetailsReducer,
  myBuildList: myBuildListReducer,
  buildCreate: buildCreateReducer,
  toApproveBuildList: toApproveBuildListReducer,
  approveBuild: approveBuildReducer,
  buildReviewList: buildReviewListReducer,
  reviewBuild: reviewBuildReducer,
  filteredBuilds: filterBuildReducer,
  savedBuildsList: savedbuildsListReducer,
  buildBadgeInfo: buildBadgeInfoReducer,
  badgeBuild: badgeBuildReducer,
  followBuild: followBuildReducer,
  saveBuild: saveBuildReducer,
  buildFollowers: buildFollowersListReducer,
  unfollowBuild: unfollowBuildReducer,
  unsaveBuild: unsaveBuildReducer,
  deleteBuild: deleteBuildReducer,
  updateBuild: updateBuildReducer,
  featureBuildList: featureBuildListReducer,
  featureBuild: featureBuildReducer,
  buildsBySection: buildsBySectionReducer,
  buildFeatureCount: buildFeatureCountReducer,
  followedBuildsList: followedBuildsListReducer,

  modList: modListReducer,
  modCreate: modCreateReducer,
  deleteMod: deleteModReducer,
  

  productList: productListReducer,
  productsByBuild: allproductsByBuildReducer,
  productDetails: productDetailsReducer,
  productCreate: productCreateReducer,
  productsToApproveList: productsToApproveListReducer,
  approveProduct: approveProductReducer,
  productReviewList: productsToReviewListReducer,
  reviewProduct: reviewProductReducer,
  saveProduct: saveProductReducer,
  unsaveProduct: unsaveProductReducer,
  savedProductsList: savedProductsListReducer,
  savedProductsByMod: savedProductsByModReducer,
  updateProduct: updateProductReducer,
  deleteProduct: deleteProductReducer,
  missingAFLProducts: productsToAddAFLReducer,
  addProductAFL: addProductAFLReducer,
  createDeal: createDealReducer,
  productDeals: productDealsReducer,
  editDeal: editDealReducer,
  updateDeal: updateDealReducer,

  userLogin: userLoginReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userRegister: userRegisterReducer,
  userNotifications: userNotificationsReducer,
  clearUserNotifications: clearUserNotificationsReducer,
  emailPreferences: emailPreferencesReducer,
  emailPreferencesUpdate: emailPreferencesUpdateReducer,
  passwordCode: resetPasswordCodeRequest,
  resetPassword: resetPasswordRequest,
});

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const initialState = {
  userLogin: {
    userInfo: userInfoFromStorage,
  },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  process.env.NODE_ENV === "development"
    ? composeWithDevTools(applyMiddleware(...middleware))
    : applyMiddleware(...middleware)
);

export default store;
