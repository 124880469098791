import React, { useCallback } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

// function titleCase(string) {
//   return string[0].toUpperCase() + string.slice(1).toLowerCase();
// }


const BuildCard = (props) => {
  const dispatch = useDispatch();
  const build = props.build;
  
  const MyImage = useCallback(({ image }) => (
    <div>
      <LazyLoadImage
        effect="blur"
        height={200}
        src={image} // use normal <img> attributes as props
        width={300}
        style={{ borderRadius: "5px" }}
      />
      {/* <span>{image.caption}</span> */}
    </div>
  ), []);

  return (
    <Card className="mx-3 my-3 " style={{border:'0px', backgroundColor: "whitesmoke", borderRadius:'5px'}}>
      <Link to={`/build/${build._id}`}>
        <div className="text-center my-3" style={{
          backgroundColor: "whitesmoke",
          
        }}>
        <MyImage image={build.mainImage} />
        </div>
      </Link>
      <Card.Body
        style={{
          backgroundColor: "whitesmoke",
        }}
      >
        {/* <Card.Text className="text-center"> */}
        <div
          className="my-1 mx-1 text-center "
          style={{
            backgroundColor: "#06395e",
            fontStyle: "Bold",
            color: "whitesmoke",
            borderRadius: "5px",
          }}
        >
          {build.buildName}
        </div>
        {/* </Card.Text> */}
      </Card.Body>
    </Card>
  );
};

export default BuildCard;
