export const BUILD_LIST_REQUEST = 'BUILD_LIST_REQUEST';
export const BUILD_LIST_SUCCESS = 'BUILD_LIST_SUCCESS';
export const BUILD_LIST_FAIL = 'BUILD_LIST_FAIL';

export const BUILD_DETAILS_REQUEST = 'BUILD_DETAILS_REQUEST';
export const BUILD_DETAILS_SUCCESS = 'BUILD_DETAILS_SUCCESS';
export const BUILD_DETAILS_FAIL = 'BUILD_DETAILS_FAIL';

export const BUILD_CREATE_REQUEST = 'BUILD_CREATE_REQUEST';
export const BUILD_CREATE_SUCCESS = 'BUILD_CREATE_SUCCESS';
export const BUILD_CREATE_FAIL = 'BUILD_CREATE_FAIL';
export const BUILD_CREATE_RESET = 'BUILD_CREATE_RESET';

export const BUILD_UPDATE_REQUEST = 'BUILD_UPDATE_REQUEST';
export const BUILD_UPDATE_SUCCESS = 'BUILD_UPDATE_SUCCESS';
export const BUILD_UPDATE_FAIL = 'BUILD_UPDATE_FAIL';
export const BUILD_UPDATE_RESET = 'BUILD_UPDATE_RESET';


export const BUILD_DELETE_REQUEST = 'BUILD_DELETE_REQUEST';
export const BUILD_DELETE_SUCCESS = 'BUILD_DELETE_SUCCESS';
export const BUILD_DELETE_FAIL = 'BUILD_DELETE_FAIL';
export const BUILD_DELETE_RESET = 'BUILD_DELETE_RESET';

export const BUILD_MY_BUILDS_REQUEST = 'BUILD_MY_BUILDS_REQUEST';
export const BUILD_MY_BUILDS_SUCCESS = 'BUILD_MY_BUILDS_SUCCESS';
export const BUILD_MY_BUILDS_FAIL = 'BUILD_MY_BUILDS_FAIL';

export const BUILDS_TO_APPROVE_REQUEST = 'BUILDS_TO_APPROVE_REQUEST';
export const BUILDS_TO_APPROVE_SUCCESS = 'BUILDS_TO_APPROVE_SUCCESS';
export const BUILDS_TO_APPROVE_FAIL = 'BUILDS_TO_APPROVE_FAIL';

export const APPROVE_BUILD_REQUEST = 'APPROVE_BUILD_REQUEST';
export const APPROVE_BUILD_SUCCESS = 'APPROVE_BUILD_SUCCESS';
export const APPROVE_BUILD_FAIL = 'APPROVE_BUILD_FAIL';

export const REVIEW_BUILD_REQUEST = 'REVIEW_BUILD_REQUEST';
export const REVIEW_BUILD_SUCCESS = 'REVIEW_BUILD_SUCCESS';
export const REVIEW_BUILD_FAIL = 'REVIEW_BUILD_FAIL';

export const BUILD_REVIEW_LIST_REQUEST = 'BUILD_REVIEW_LIST_REQUEST';
export const BUILD_REVIEW_LIST_SUCCESS = 'BUILD_REVIEW_LIST_SUCCESS';
export const BUILD_REVIEW_LIST_FAIL = 'BUILD_REVIEW_LIST_FAIL';

export const BUILD_FILTER_REQUEST = 'BUILD_FILTER_REQUEST';
export const BUILD_FILTER_SUCCESS = 'BUILD_FILTER_SUCCESS';
export const BUILD_FILTER_FAIL = 'BUILD_FILTER_FAIL';
export const BUILD_FILTER_RESET = 'BUILD_FILTER_RESET';

export const SAVED_BUILDS_LIST_REQUEST = 'SAVED_BUILDS_LIST_REQUEST';
export const SAVED_BUILDS_LIST_SUCCESS = 'SAVED_BUILDS_LIST_SUCCESS';
export const SAVED_BUILDS_LIST_FAIL = 'SAVED_BUILDS_LIST_FAIL';
export const SAVED_BUILDS_LIST_RESET = 'SAVED_BUILDS_LIST_RESET';

export const FOLLOWED_BUILDS_LIST_REQUEST = 'FOLLOWED_BUILDS_LIST_REQUEST';
export const FOLLOWED_BUILDS_LIST_SUCCESS = 'FOLLOWED_BUILDS_LIST_SUCCESS';
export const FOLLOWED_BUILDS_LIST_FAIL = 'FOLLOWED_BUILDS_LIST_FAIL';
export const FOLLOWED_BUILDS_LIST_RESET = 'FOLLOWED_BUILDS_LIST_RESET';


export const SAVE_BUILD_REQUEST = 'SAVE_BUILD_REQUEST';
export const SAVE_BUILD_SUCCESS = 'SAVE_BUILD_SUCCESS';
export const SAVE_BUILD_FAIL = 'SAVE_BUILD_FAIL';
export const SAVE_BUILD_RESET = 'SAVE_BUILD_RESET';

export const UNSAVE_BUILD_REQUEST = 'UNSAVE_BUILD_REQUEST';
export const UNSAVE_BUILD_SUCCESS = 'UNSAVE_BUILD_SUCCESS';
export const UNSAVE_BUILD_FAIL = 'UNSAVE_BUILD_FAIL';
export const UNSAVE_BUILD_RESET = 'UNSAVE_BUILD_RESET';

export const FOLLOW_BUILD_REQUEST = 'FOLLOW_BUILD_REQUEST';
export const FOLLOW_BUILD_SUCCESS = 'FOLLOW_BUILD_SUCCESS';
export const FOLLOW_BUILD_FAIL = 'FOLLOW_BUILD_FAIL';
export const FOLLOW_BUILD_RESET = 'FOLLOW_BUILD_RESET';

export const BUILD_FOLLOWERS_LIST_REQUEST = 'BUILD_FOLLOWERS_LIST_REQUEST';
export const BUILD_FOLLOWERS_LIST_SUCCESS = 'BUILD_FOLLOWERS_LIST_SUCCESS';
export const BUILD_FOLLOWERS_LIST_FAIL = 'BUILD_FOLLOWERS_LIST_FAIL';
export const BUILD_FOLLOWERS_LIST_RESET = 'BUILD_FOLLOWERS_LIST_RESET';


export const UNFOLLOW_BUILD_REQUEST = 'UNFOLLOW_BUILD_REQUEST';
export const UNFOLLOW_BUILD_SUCCESS = 'UNFOLLOW_BUILD_SUCCESS';
export const UNFOLLOW_BUILD_FAIL = 'UNFOLLOW_BUILD_FAIL';
export const UNFOLLOW_BUILD_RESET = 'UNFOLLOW_BUILD_RESET';

export const BADGE_BUILD_REQUEST = 'BADGE_BUILD_REQUEST';
export const BADGE_BUILD_SUCCESS = 'BADGE_BUILD_SUCCESS';
export const BADGE_BUILD_FAIL = 'BADGE_BUILD_FAIL';
export const BADGE_BUILD_RESET = 'BADGE_BUILD_RESET';

export const GET_BADGE_INFO_REQUEST = 'GET_BADGE_INFO_REQUEST';
export const GET_BADGE_INFO_SUCCESS = 'GET_BADGE_INFO_SUCCESS';
export const GET_BADGE_INFO_FAIL = 'GET_BADGE_INFO_FAIL';

export const FEATURE_BUILD_LIST_REQUEST = 'FEATURE_BUILD_LIST_REQUEST';
export const FEATURE_BUILD_LIST_SUCCESS = 'FEATURE_BUILD_LIST_SUCCESS';
export const FEATURE_BUILD_LIST_FAIL = 'FEATURE_BUILD_LIST_FAIL';
export const FEATURE_BUILD_LIST_RESET = 'FEATURE_BUILD_LIST_RESET';

export const MAKE_BUILD_FEATURE_REQUEST = 'MAKE_BUILD_FEATURE_REQUEST';
export const MAKE_BUILD_FEATURE_SUCCESS = 'MAKE_BUILD_FEATURE_SUCCESS';
export const MAKE_BUILD_FEATURE_FAIL = 'MAKE_BUILD_FEATURE_FAIL';
export const MAKE_BUILD_FEATURE_RESET = 'MAKE_BUILD_FEATURE_RESET';

export const LIST_BUILDS_BY_SECTION_REQUEST = 'LIST_BUILDS_BY_SECTION_REQUEST';
export const LIST_BUILDS_BY_SECTION_SUCCESS = 'LIST_BUILDS_BY_SECTION_SUCCESS';
export const LIST_BUILDS_BY_SECTION_FAIL = 'LIST_BUILDS_BY_SECTION_FAIL';
export const LIST_BUILDS_BY_SECTION_RESET = 'LIST_BUILDS_BY_SECTION_RESET';

export const BUILD_FEATURE_COUNT_REQUEST = 'BUILD_FEATURE_COUNT_REQUEST';
export const BUILD_FEATURE_COUNT_SUCCESS = 'BUILD_FEATURE_COUNT_SUCCESS';
export const BUILD_FEATURE_COUNT_FAIL = 'BUILD_FEATURE_COUNT_FAIL';

