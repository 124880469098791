import React, { useEffect, useState } from "react";
import { Row, Col, Container, Form } from "react-bootstrap";
import { listFilteredBuilds, searchFilterReset } from "../actions/buildActions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { carData } from "../helpers/carData";
import { useLocation } from "react-router";

const SearchBar = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const setSearch = props.setSearch;
  const reset = props.reset;

  //const [buildYear, setBuildYear] = useState("");
  const [fromYear, setFromYear] = useState("");
  const [toYear, setToYear] = useState("");

  const [oemBrand, setOemBrand] = useState("");
  const [oemModel, setOemModel] = useState("");
  const [modType, setModType] = useState("");

  const year = new Date().getFullYear();

  useEffect(() => {
    if (reset) {
      setFromYear("");
      setToYear("");
      setOemBrand("");
      setOemModel("");
      setModType("");
      setSearch(false);
      navigate(location.pathname);
    }
    if (fromYear && toYear && oemBrand && !modType && !oemModel) {
      setSearch(true);
      dispatch(searchFilterReset());
      dispatch(
        listFilteredBuilds({
          fromYear,
          toYear,
          oemBrand,
        })
      );
    }

    if (fromYear && toYear && oemBrand && modType && !oemModel) {
      setSearch(true);
      dispatch(searchFilterReset());
      dispatch(
        listFilteredBuilds({
          fromYear,
          toYear,
          oemBrand,
          modType,
        })
      );
    }

    if (fromYear && toYear && oemBrand && oemModel && !modType) {
      setSearch(true);
      dispatch(searchFilterReset());
      dispatch(
        listFilteredBuilds({
          fromYear,
          toYear,
          oemBrand,
          oemModel,
        })
      );
    }

    if (fromYear && toYear && oemBrand && oemModel && modType) {
      setSearch(true);
      dispatch(searchFilterReset());
      dispatch(
        listFilteredBuilds({
          fromYear,
          toYear,
          oemBrand,
          oemModel,
          modType,
        })
      );
    }
  }, [
    fromYear,
    toYear,
    oemBrand,
    oemModel,
    modType,
    reset,
    setSearch,
    dispatch,
    setSearch,
  ]);



  return (
    <div>
      <Container
        variant="success"
        style={{
          backgroundColor: "#5fb3b3",
          color: "white",
          padding: "10px",
          borderRadius: "10px",
        }}
        className="justify-content-center fill"
      >
        <Row className="justify-content-center">
          <Row as="div" className="justify-content-center flex">
            <Col
              sm={1}
              md={3}
              lg={3}
              className="text-right"
              // style={{backgroundColor:'#f2f2f2', borderRadius:'10px', color:'black'}}
            >
              <div className="my-2 mx-3 text-center ">
                <h6 className=" my-2 text-right"> From: </h6>
              </div>
            </Col>
            <Col sm={1} md={2} lg={3}>
              <Form.Select
                className="my-1"
                aria-label="Floating label select example"
                placeholder="Select Year"
                value={fromYear ? fromYear : ""}
                onChange={(e) => {
                  setFromYear(e.target.value);
                }}
              >
                <option>Year</option>
                {Array.from(Array(73).keys()).map((year) => (
                  <option key={year}>{new Date().getFullYear() - year}</option>
                ))}
              </Form.Select>
            </Col>
            <Col
              sm={1}
              md={1}
              lg={1}
              // style={{backgroundColor:'#f2f2f2', borderRadius:'10px', color:'black'}}
            >
              <div className="my-2 text-center">
                <h6> To: </h6>
              </div>
            </Col>
            <Col sm={1} md={2} lg={3}>
              <Form.Select
                className="my-1"
                aria-label="Floating label select example"
                placeholder="Select Year"
                value={toYear ? toYear : ""}
                onChange={(e) => {
                  setToYear(e.target.value);
                }}
                disabled={(fromYear === "" || fromYear === "Year") && true}
              >
                <option>Year</option>
                {Array.from(
                  Array(new Date().getFullYear() - Number(fromYear)).keys()
                ).map((year) => (
                  <option key={year}>{new Date().getFullYear() - year}</option>
                ))}
              </Form.Select>
            </Col>
          </Row>

          <Row className="justify-content-center my-2">
            <Col sm={1} md={2} lg={3} className="mx-3">
              <Form.Select
                className="my-2"
                aria-label="Floating label select example"
                placeholder="Select Make"
                value={oemBrand ? oemBrand : ""}
                onChange={(e) => {
                  setOemBrand(e.target.value);
                }}
              >
                {carData &&
                  carData.map((car) => (
                    <option key={car.make}>{car.make}</option>
                  ))}
              </Form.Select>
            </Col>
            <Col sm={1} md={2} lg={3} className="mx-4">
              <Form.Select
                className="my-2 mx-1"
                aria-label="Floating label select example"
                placeholder="Select Model"
                value={oemModel ? oemModel : ""}
                onChange={(e) => {
                  setOemModel(e.target.value);
                }}
              >
                {carData &&
                  oemBrand &&
                  oemBrand !== "Select Make" &&
                  carData
                    .filter((car) => car.make === oemBrand)
                    .map((car) => car.models)[0]
                    .map((model) => <option key={model}>{model}</option>)}
                {!oemBrand && <option>Select Model</option>}
              </Form.Select>
            </Col>

            <Col sm={1} md={2} lg={3} className="mx-3">
              <Form.Select
                className="my-2 mx-1"
                aria-label="Floating label select example"
                placeholder="Select Make"
                value={modType ? modType : ""}
                onChange={(e) => {
                  setModType(e.target.value);
                }}
              >
                <option>Modification</option>
                <option value="All">All</option>
                <option value="Front Bumper">Front Bumper</option>
                <option value="Rear Bumper">Rear Bumper</option>
                <option value="Rock Sliders">Rock Sliders</option>
                <option value="Skid Plates">Skid Plates</option>
                <option value="Roof Rack">Roof Rack</option>
                <option value="Recovery Gear">Recovery Gear</option>
                <option value="Storage">Storage</option>
                <option value="Accessories">Accessories</option>
                <option value="Tent">Tent</option>
                <option value="Lights">Lights</option>
                <option value="Engine">Engine</option>
                <option value="Navigation">Navigation</option>
                <option value="Suspension">Suspension</option>
                <option value="Auxilary Power">Auxilary Power</option>
                <option value="Wheels">Wheels</option>
                <option value="Tires">Tires</option>
              </Form.Select>
            </Col>
          </Row>
        </Row>
      </Container>
    </div>
  );
};

export default SearchBar;
