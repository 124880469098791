import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Container,
  Form,
  FormControl,
} from "react-bootstrap";
import {} from "../actions/buildActions";
import { useDispatch } from "react-redux";
import { useNavigate,createSearchParams } from "react-router-dom";
import { useLocation } from "react-router";

const KeywordSearch = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const setSearchKeyword = props.setSearchKeyword;
  const reset = props.reset;
  const setKeywordResult = props.setKeywordResult;
  const adminMode = props.adminMode;

  const [keyword, setKeyword] = useState("");
  const [sendSearch, setSendSearch] = useState(false);

 

  const submitHandler = (e) => {
    e.preventDefault();
    setSendSearch(true);
    if (keyword ) {
      navigate({
        pathname: location.pathname, 
        search: `?${createSearchParams({
          keyword: keyword,
        })}`,
      });
      
    } else {
      navigate(location.pathname);
    }
    setKeyword("");
  };

  useEffect(() => {
    if (reset) {
      setKeyword("");
      setSearchKeyword(false);
      if(setKeywordResult){
      setKeywordResult(false);
      }
    }
    
  }, [keyword, reset, setSearchKeyword, sendSearch, setKeywordResult,adminMode]);

  return (
    <div>
      <Container
        variant="success"
        style={{
          backgroundColor: "#5fb3b3",
          color: "white",
          padding: "10px",
          borderRadius: "10px",
        }}
        className="justify-content-center"
      >
        <Row className="justify-content-center">
          <Form
            className="d-flex mx-2 my-2"
            onSubmit={submitHandler}
          >
            <FormControl
              type="search"
              placeholder="Search by Build name, IG Handle, or Youtube name"
              className="me-2"
              aria-label="Search"
              onChange={(e) => setKeyword(e.target.value)}
            />
            <Button variant="primary" type="submit">
              Search
            </Button>
          </Form>
        </Row>
      </Container>
    </div>
  );
};

export default KeywordSearch;
