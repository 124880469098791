import {
   MODS_REQUEST,
    MODS_REQUEST_SUCCESS,
    MODS_REQUEST_FAIL,
    MOD_CREATE_REQUEST,
    MOD_CREATE_REQUEST_SUCCESS,
    MOD_CREATE_REQUEST_FAIL,
    MOD_DELETE_REQUEST,
    MOD_DELETE_SUCCESS,
    MOD_DELETE_FAIL,
    MOD_DELETE_RESET,
    MOD_CREATE_RESET
  } from "../constants/modConstants";
  
  export const modListReducer = (state = { mods: [] }, action) => {
    switch (action.type) {
      case MODS_REQUEST:
        return { loading: true, mods: [] };
  
      case MODS_REQUEST_SUCCESS:
        return {
          loading: false,
          mods: action.payload,
          
        };
      case MODS_REQUEST_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };

  export const modCreateReducer = (state = {}, action) => {
    switch (action.type) {
      case MOD_CREATE_REQUEST:
        return { loading: true };
  
      case MOD_CREATE_REQUEST_SUCCESS:
        return {
          loading: false,
          success : true,
          mod: action.payload,
        };
      case MOD_CREATE_REQUEST_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
      case MOD_CREATE_RESET:
        return {};
      default:
        return state;
    }
  }

  export const deleteModReducer = (state = { delete: [] }, action) => {
    switch (action.type) {
      case MOD_DELETE_REQUEST:
        return { loading: true, delete: [] };
  
      case MOD_DELETE_SUCCESS:
        return {
          loading: false,
          delete: action.payload,
          success: true,
        };
      case MOD_DELETE_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
      case MOD_DELETE_RESET:
        return {}
      default:
        return state;
    }
  };