import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Button, Container, Alert } from "react-bootstrap";
import BuildCard_v2 from "../Components/BuildCard_v2";
import Loader from "../Components/Loader";
import Message from "../Components/Message";
import { useDispatch, useSelector } from "react-redux";
import { listbuilds } from "../actions/buildActions";
import SearchBar from "../Components/SearchBar";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import KeywordSearch from "../Components/KeywordSearch";
import Paginate from "../Components/Paginate";
import FeaturedBuildsSection from "../Components/FeaturedBuildsSection";
import { listDealsAction } from "../actions/productActions";
import DealCard from "../Components/DealCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { listSavedProducts } from "../actions/productActions";

const HomeScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const buildList = useSelector((state) => state.buildList);
  const { builds, loading, error, page, pages } = buildList;

  const filteredBuilds = useSelector((state) => state.filteredBuilds);
  const {
    builds: searchBuilds,
    loading: searchLoading,
    error: searchError,
  } = filteredBuilds;

  const productDeals = useSelector((state) => state.productDeals);
  const { deals, loading: dealsLoading, error: dealsError } = productDeals;

  const savedProducts = useSelector((state) => state.savedProductsList);
  const {
    products: savedProductsList,
    loading: savedProductsLoading,
    error: savedProductsError,
  } = savedProducts;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [search, setSearch] = useState(false);
  const [reset, setReset] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState(false);
  const [viewall, setViewall] = useState(false);
  const [show, setShow] = useState(false);

  let keyword = location.search ? location.search : "";

  const viewAllHandler = () => {
    setViewall(true);
  };

  viewall && window.scrollTo(0, 100);

  const createABuildHandler = () => {
    if (userInfo) {
      navigate(`/createbuild/${userInfo._id}`);
    } else {
      setShow(true);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (reset || !builds || builds.length === 0) {
      dispatch(listbuilds(keyword));
    }
    if (searchKeyword) {
      dispatch(listbuilds(keyword));
    }
    
    dispatch(listbuilds(keyword));
   
    
    if (!search) {
      setReset(false);
    }
    if (!searchKeyword) {
      setReset(false);
    }
    if (!deals || deals.length === 0) {
      dispatch(listDealsAction());
    }
    if (!savedProductsList) {
      dispatch(listSavedProducts());
    }
  }, [dispatch, reset, search, keyword]);

  const clearSearchHandler = () => {
    setReset(true);
    setSearchKeyword(false);
    setSearch(false);

    navigate(location.pathname);
  };

  return (
    <Fragment>
      <div style={{ backgroundColor: "#ebeced" }}>
        <Container
          variant="success"
          style={{
            //backgroundColor: "#adb5bd",
            backgroundColor: "#ebeced",
            color: "white",
            padding: "10px",
            borderRadius: "10px",
          }}
          className="justify-content-center mt-0"
        >
          <Row sm={2} md={10} lg={12} className="text-center my-1">
          
            {/* <Col sm={1} md={3} lg={4}>
              <div>
                <h5 className=" my-2 align-items-center" style={{color:'black'}}>
                  Search Builds, Mods, and Products:
                </h5>
              </div>
            </Col> */}

            <Col sm={2} md={2} lg={2} xl={3} className="my-1">
              <Button
                variant="primary"
                onClick={() => {
                  setShowSearch(!showSearch);
                  searchKeyword && setSearchKeyword(!searchKeyword);
                }}
                style={{ borderRadius: "3px" }}
              >
                {showSearch ? "Hide Search Bar" : "FILTER VEHICLES"}
              </Button>
            </Col>
            <Col sm={2} md={2} lg={2} xl={3} className="my-1">
              <Button
                variant="primary"
                onClick={() => {
                  setSearchKeyword(!searchKeyword);
                  showSearch && setShowSearch(!showSearch);
                }}
                style={{ borderRadius: "3px" }}
              >
                {searchKeyword ? "Hide Search Bar" : "SEARCH BY KEYWORD"}
              </Button>
            </Col>
            
            <Col sm={2} md={2} lg={2} xl={3} className="my-1">
              <Button
                variant="success"
                onClick={() => {
                  if(userInfo){
                  navigate('/savedbuilds');
                  }else{
                    setShow(true);
                  }
                }}
                style={{ borderRadius: "3px" }}
              >
                FOLLOWED BUILDS
              </Button>
            </Col>
            <Col sm={2} md={2} lg={2} xl={3} className="my-1">
              <Button
                onClick={createABuildHandler}

                variant="warning"
                style={{ borderRadius: "3px" }}
              >
                CREATE A BUILD
              </Button>
            </Col>
           
          </Row>
        </Container>
      </div>
      <div className="my-2">
        <Row sm={1} md={12} lg={12} className="text-center my-3">
          {showSearch && !searchKeyword && (
            <SearchBar
              setSearch={setSearch}
              reset={reset}
              setReset={setReset}
            />
          )}
          {searchKeyword && !showSearch && !reset && (
            <KeywordSearch
              setSearchKeyword={setSearchKeyword}
              reset={reset}
              setReset={setReset}
            />
          )}
        </Row>
        <Alert
          show={show}
          variant="warning"
          onClose={() => setShow(false)}
          dismissible
        >
          <Alert.Heading>
            Oh snap! You need to login to create or follow a build!
          </Alert.Heading>
          <p>
            You can login or register <Link to="/login">here. </Link>
          </p>
        </Alert>

        {!search && !searchKeyword && !viewall && (
          <FeaturedBuildsSection criteria={"featured"} />
        )}

        {!search && !searchKeyword && !viewall && (
          <FeaturedBuildsSection criteria={"popular"} />
        )}
        {!search && !searchKeyword && !viewall && (
          <>
            <FeaturedBuildsSection
              criteria={"new"}
              className="mb-0"
              setViewall={setViewall}
            />
          </>
        )}

        {!search && !searchKeyword && !viewall && (
          <>
            <DealCard
              deals={deals}
              loading={dealsLoading}
              error={dealsError}
              className="text-center"
            />
            <div className="text-center my-2">
              <Button
                className="mx-auto my-3"
                variant="success"
                size="lg"
                style={{ borderRadius: "5px" }}
                onClick={() => {
                  navigate("/productdeals");
                }}
              >
                View All Deals
              </Button>
            </div>
          </>
        )}
        {/* {!search && !searchKeyword && (
        <div className="text-center my-3">
          <Button
            variant={viewall ? "warning" : "success"}
            size="lg"
            onClick={() => {
              !viewall && setViewall(true);
              viewall && setViewall(false);
            }}
          >
            {" "}
            {!viewall ? "View All Builds" : "Back to Home Screen"}
          </Button>
        </div>
      )} */}

        {searchLoading && <Loader />}
        {searchError && <Message variant="danger">{searchError}</Message>}
        {search && searchBuilds && searchLoading && <Loader />}
       
        {search && searchBuilds && (
          <Container className="p-2">
            <Row className="p-2">
              <Col sm={1} md={2} lg={2} xl={2}>
                <Button
                  className="my-3"
                  variant="outline-warning"
                  onClick={clearSearchHandler}
                >
                  Clear Search
                </Button>
              </Col>
              <Col sm={8} md={6} lg={8} xl={8}>
                <h3 className="my-3 text-center">Search Results</h3>
              </Col>
            </Row>
            {searchBuilds.length === 0 && !searchLoading && (
                <h4>
                Snap! No builds found. Try a different search.
                </h4>
              )}
            {searchBuilds.map((build) => (
              <Col sm={12} md={8} lg={6} xl={6} key={build._id}>
                <BuildCard_v2 build={build} />
              </Col>
            ))}
          </Container>
        )}

        {searchKeyword && !search && keyword && (
          <Container className="p-2">
            <Row className="p-2">
              <Col sm={1} md={2} lg={2} xl={2}>
                <Button
                  className="my-3"
                  variant="outline-warning"
                  onClick={clearSearchHandler}
                >
                  Clear Search
                </Button>
              </Col>
              <Col sm={8} md={6} lg={8} xl={8}>
                <h3 className="my-3 text-center">Keyword Search Results</h3>
              </Col>
            </Row>
            <Row>
              {builds.length === 0 && !loading && (
                <h4>
                Snap! No builds found. Try a different search.
                </h4>
              )}
              {builds.map((build) => (
                <Col sm={12} md={8} lg={6} xl={4} key={build._id}>
                  {/* <BuildCard build={build} /> */}
                  <BuildCard_v2 build={build} />
                </Col>
              ))}
            </Row>
          </Container>
        )}

        {viewall && (
          <>
            {loading && <Loader />}
            {error && <Message variant="danger">{error}</Message>}
            {builds && !search && !searchKeyword && (
              <>
                <Link
                  to="/"
                  onClick={() => {
                    setViewall(false);
                  }}
                  className="btn btn-light my-3 mx-3"
                >
                  <FontAwesomeIcon icon={faArrowLeft} /> Back to Home Page
                </Link>
                <Container className="justify-content-center">
                  <Row>
                    <h3 className="text-center">All Builds</h3>
                    {builds.map((build) => (
                      <Col sm={12} md={6} lg={4} xl={4} key={build._id}>
                        {/* <BuildCard build={build} /> */}
                        <BuildCard_v2 build={build} />
                      </Col>
                    ))}
                  </Row>
                </Container>
                <Container className="text-center" align="center">
                  <Row className="text-center" align="center">
                    <Col sm={1} md={2} lg={2}></Col>
                    <Col className="text-center" align="center">
                      <Paginate
                        className="text-center"
                        align="center"
                        page={page}
                        pages={pages}
                      />
                    </Col>
                    <Col sm={1} md={2} lg={2}></Col>
                  </Row>
                </Container>
              </>
            )}
          </>
        )}
      </div>
    </Fragment>
  );
};

export default HomeScreen;
