import React from "react";
import SkeletonElement from "./SkeletonElement";
import { Col, Row } from "react-bootstrap";
import Shimmer from "./Shimmer";

const SkeletonBuildFeatured = () => {
  return (
    <div className="skeleton-wrapper">
      <div className="">
          <Row>
        {[1,2,3,4].map((item) => (
            <Col sm={10} md={6} lg={3} xl={3} key={item} align='center' >
                <SkeletonElement type="photo" />
                <SkeletonElement type="title" />
                
                </Col>
        ))}
        </Row>
        
      </div>
      <Shimmer/>
    </div>
  );
};

export default SkeletonBuildFeatured;
