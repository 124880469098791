import {
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,
    USER_LOGOUT,
    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
    USER_REGISTER_FAIL,
    USER_DETAILS_REQUEST,
    USER_DETAILS_SUCCESS,
    USER_DETAILS_FAIL,
    USER_UPDATE_PROFILE_REQUEST,
    USER_UPDATE_PROFILE_SUCCESS,
    USER_UPDATE_PROFILE_FAIL,
    USER_DETAILS_RESET,
    USER_LIST_FAIL,
    USER_LIST_REQUEST,
    USER_LIST_SUCCESS,
    USER_LIST_RESET,
    USER_DELETE_SUCCESS,
    USER_DELETE_FAIL,
    USER_DELETE_REQUEST,
    USER_UPDATE_REQUEST,
    USER_UPDATE_SUCCESS,
    USER_UPDATE_FAIL,
    USER_NOTIFICATIONS_REQUEST,
    USER_NOTIFICATIONS_SUCCESS,
    USER_NOTIFICATIONS_FAIL,
    USER_NOTIFICATIONS_RESET,
    CLEAR_USER_NOTIFICATIONS_REQUEST,
    CLEAR_USER_NOTIFICATIONS_SUCCESS,
    CLEAR_USER_NOTIFICATIONS_FAIL,
    EMAIL_PREFERENCES_REQUEST,
    EMAIL_PREFERENCES_SUCCESS,
    EMAIL_PREFERENCES_FAIL,
    EMAIL_PREFERENCES_UPDATE_REQUEST,
    EMAIL_PREFERENCES_UPDATE_SUCCESS,
    EMAIL_PREFERENCES_UPDATE_FAIL,
    CODE_VERIFICATION_REQUEST,
    CODE_VERIFICATION_SUCCESS,
    CODE_VERIFICATION_FAIL,
    PASSWORD_RESET_REQUEST,
    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_FAIL,
  } from "../constants/userConstants";

  import {
    SAVED_BUILDS_LIST_RESET,
    BUILD_FOLLOWERS_LIST_RESET,} from "../constants/buildConstants";
  import { SAVED_PRODUCTS_LIST_RESET } from "../constants/productConstants";  
  
  import axios from "axios";
  
  
  
  export const login = (email, password) => async (dispatch) => {
    try {
      dispatch({
        type: USER_LOGIN_REQUEST,
      });
  
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
  
      
      const { data } = await axios.post(
        "https://obbackend.herokuapp.com/api/users/login/",
        { "username": email, "password": password },
        config
      );
  
      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
      });
  
      localStorage.setItem("userInfo", JSON.stringify(data));
    } catch (error) {
      dispatch({
        type: USER_LOGIN_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };
  
  export const logout = () => (dispatch) => {
    localStorage.removeItem("userInfo");
    
    dispatch({ type: USER_LOGOUT })
    dispatch({ type: USER_DETAILS_RESET });
    dispatch({ type: USER_LIST_RESET });
    dispatch({ type: SAVED_BUILDS_LIST_RESET });
    dispatch({ type: BUILD_FOLLOWERS_LIST_RESET });
    dispatch({ type: SAVED_PRODUCTS_LIST_RESET });
  };
  
  export const register = (name, email, password) => async (dispatch) => {
    try {
      dispatch({
        type: USER_REGISTER_REQUEST,
      });
  
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
  
      const { data } = await axios.post(
        "https://obbackend.herokuapp.com/api/users/register/",
        { name: name, email: email, password: password },
        config
      );
  
      dispatch({
        type: USER_REGISTER_SUCCESS,
        payload: data,
      });
  
      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
      });
  
      localStorage.setItem("userInfo", JSON.stringify(data));
    } catch (error) {
      dispatch({
        type: USER_REGISTER_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };
  
  export const getUserDetails = (id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_DETAILS_REQUEST,
      });
  
      const {
        userLogin: { userInfo },
      } = getState();
      
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
  
      const { data } = await axios.get(`https://obbackend.herokuapp.com/api/users/${id}/`, config);
  
      dispatch({
        type: USER_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_DETAILS_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };
  
  export const updateUserProfile = (user) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_UPDATE_PROFILE_REQUEST,
      });
  
      const {
        userLogin: { userInfo },
      } = getState();
      
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
  
      const { data } = await axios.put(`https://obbackend.herokuapp.com/api/users/profile/update/`, user,config);
  
      dispatch({
        type: USER_UPDATE_PROFILE_SUCCESS,
        payload: data,
      });
  
      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
      });
  
      localStorage.setItem("userInfo", JSON.stringify(data));
  
  
    } catch (error) {
      dispatch({
        type: USER_UPDATE_PROFILE_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };
  
  export const listUsers = (keyword='') => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_LIST_REQUEST,
      });
  
      const {
        userLogin: { userInfo },
      } = getState();
      
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
  
      const { data } = await axios.get(`https://obbackend.herokuapp.com/api/users/${keyword}`,config);
  
      dispatch({
        type: USER_LIST_SUCCESS,
        payload: data,
      });
  
    } catch (error) {
      dispatch({
        type: USER_LIST_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };
  
  export const actionDeleteUser = (userId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_DELETE_REQUEST,
      });
  
      const {
        userLogin: { userInfo },
      } = getState();
      
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
  
      const { data } = await axios.delete(`https://obbackend.herokuapp.com/api/users/delete/${userId}`,config);
  
      dispatch({
        type: USER_DELETE_SUCCESS,
        payload: data,
      });
  
    } catch (error) {
      dispatch({
        type: USER_DELETE_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };
  
  
  
  export const updateUser = (user) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_UPDATE_REQUEST,
      });
  
      const {
        userLogin: { userInfo },
      } = getState();
      
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
  
      const { data } = await axios.put(`https://obbackend.herokuapp.com/api/users/update/${user._id}`,user,config);
  
      dispatch({
        type: USER_UPDATE_SUCCESS,
        
      });
  
      dispatch({
        type: USER_DETAILS_SUCCESS,
        payload: data,
        
      });
  
    } catch (error) {
      dispatch({
        type: USER_UPDATE_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };
  

  export const getNotificationsAction =  () => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_NOTIFICATIONS_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();
      
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const {data} = await axios.get('https://obbackend.herokuapp.com/api/users/notifications/',config);

      dispatch({
        type: USER_NOTIFICATIONS_SUCCESS,
        payload: data,
      });
    }
    catch(error){
      dispatch({
        type: USER_NOTIFICATIONS_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  }

  export const clearNotificationsAction =  () => async (dispatch) => {
    dispatch({
      type: USER_NOTIFICATIONS_RESET,
    });
  }

  export const clearNotificationAction =  (id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CLEAR_USER_NOTIFICATIONS_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();
      
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const {data} = await axios.delete(`https://obbackend.herokuapp.com/api/users/clearnotifications/${id}/`,config);

      dispatch({
        type: CLEAR_USER_NOTIFICATIONS_SUCCESS,
        payload: data,
      });
    }
    catch(error){
      dispatch({
        type: CLEAR_USER_NOTIFICATIONS_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  }

export const getEmailPreferencesAction =  () => async (dispatch, getState) => {
  try {
    dispatch({
      type: EMAIL_PREFERENCES_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();
    
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const {data} = await axios.get('https://obbackend.herokuapp.com/api/admin/usernotifications/',config);

    dispatch({
      type: EMAIL_PREFERENCES_SUCCESS,
      payload: data,
    });
  }
  catch(error){
    dispatch({
      type: EMAIL_PREFERENCES_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
}

export const updateEmailPreferencesAction =  (action) => async (dispatch, getState) => {
  try{
    dispatch({
      type: EMAIL_PREFERENCES_UPDATE_REQUEST,
    });
    
    const {
      userLogin: { userInfo },
    } = getState();
    
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    
    const {data} = await axios.put('https://obbackend.herokuapp.com/api/admin/usernotifications/',action,config);
    
    dispatch({
      type: EMAIL_PREFERENCES_UPDATE_SUCCESS,
      payload: data,
    });
  }
  catch(error){
    dispatch({
      type: EMAIL_PREFERENCES_UPDATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
}

export const getPasswordResetCodeAction =  (email) => async (dispatch) => {
  try{
    dispatch({
      type: CODE_VERIFICATION_REQUEST,
    });
    
    const {data} = await axios.put('https://obbackend.herokuapp.com/api/users/resetpasscode/', email);
    
    dispatch({
      type: CODE_VERIFICATION_SUCCESS,
      payload: data,
    });
  }
  catch(error){
    dispatch({
      type: CODE_VERIFICATION_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
}

export const resetPasswordAction =  (info) => async (dispatch) => {
  try{
    dispatch({
      type: PASSWORD_RESET_REQUEST,
    });
    
    const {data} = await axios.put('https://obbackend.herokuapp.com/api/users/passwordreset/', info);
    
    dispatch({
      type: PASSWORD_RESET_SUCCESS,
      payload: data,
    });
  }
  catch(error){
    dispatch({
      type: PASSWORD_RESET_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
}