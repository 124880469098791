import React, { useEffect } from "react";
import {
  Row,
  Col,
  Container,
  Tooltip,
  OverlayTrigger,
  Button,
} from "react-bootstrap";
import BuildCard_v2 from "../Components/BuildCard_v2";
import Message from "../Components/Message";
import { useDispatch, useSelector } from "react-redux";
import { listBuildsBySection } from "../actions/buildActions";
import { animated, useSpring } from "react-spring";
import { useScroll } from "react-use-gesture";
import { Link } from "react-router-dom";
import "./styles.scss";
import SkeletonBuildFeatured from "../skeletons/SkeletonBuildFeatured";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";

const FeaturedBuildsSection = (props) => {
  const criteria = props.criteria;
  const setViewall = props.setViewall;
  const dispatch = useDispatch();

  const buildsBySection = useSelector((state) => state.buildsBySection);
  const { buildsft, buildspl, buildsnw, loading, error } = buildsBySection;

  let bgcolor = "";

  if (criteria === "featured") {
    bgcolor = "whitesmoke";
  } else if (criteria === "new") {
    bgcolor = "whitesmoke";
  } else {
    bgcolor = "whitesmoke";
  }

  const style = {
    backgroundColor: bgcolor,
    borderRadius: "8px",
    padding: "5px",
    // animation: "ease-in-out",
    // margin: "10px",
    // alignItems: "center",
  };

  const styleLoading = {
    backgroundColor: bgcolor,
    borderRadius: "8px",
    padding: "5px",
    overflow: "hidden",
    margin_left: "20px",
    padding_left: "20px",
  };

  useEffect(() => {
    if (!buildsft || !buildspl || !buildsnw) {
    dispatch(listBuildsBySection());
    }
  }, [dispatch]);

  // const stylediv = useSpring({
  //   from: {
  //     transform: "perspective(500px) rotateY(0deg)",
  //   },
  //   transform: "perspective(500px) rotateY(25deg)",
  // });

  const [stylediv, set] = useSpring(() => ({
    transform: "perspective(500px) rotateY(0deg)",
  }));

  const clamp = (value, clampAt = 1) => {
    if (value > 0) {
      return value > clampAt ? clampAt : value;
    } else {
      return value < -clampAt ? -clampAt : value;
    }
  };
  const bind = useScroll((event) => {
    set.start({
      transform: `perspective(500px) rotateY(${
        event.scrolling ? clamp(event.delta[0]) : 0
      }deg)`,
    });
  });

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip">{props}</Tooltip>
  );

  return (
    <div>
      {loading && (
        <>
          <Container
            className="justify-content-center my-3"
            style={styleLoading}
          >
            <Row>
              <h3 className="text-center">
                {criteria === "featured" && <strong>FEATURED BUILDS</strong>}
                {criteria === "new" && "Latest Builds"}
                {criteria === "popular" && "Popular Builds"}
              </h3>

              <SkeletonBuildFeatured />
            </Row>
          </Container>
        </>
      )}
      {error && <Message variant="danger">{error}</Message>}

      {buildsft && criteria === "featured" && (
        <div style={style}>
          <h3 className="text-center mt-3" style={{ color: "#1d3554" }}>
            <strong>
              FEATURED BUILDS <FontAwesomeIcon icon={faArrowCircleRight} />{" "}
            </strong>
          </h3>
          <div className="containerSass" {...bind()}>
            {buildsft.map((build) => (
              <Link to={`/build/${build._id}`} key={build._id}>
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip(build.buildName)}
                >
                  <animated.div
                    className="cardSass"
                    style={{
                      ...stylediv,
                      backgroundImage: `url(${build.mainImage})`,
                    }}
                  />
                </OverlayTrigger>
              </Link>
            ))}
          </div>
        </div>
      )}

      {buildspl && criteria === "popular" && (
        <>
          <Container className="justify-content-center my-3" style={style}>
            <Row>
              <h3 className="text-center">
                {criteria === "featured" && "Featured Builds"}
                {criteria === "new" && "Latest Builds"}
                {criteria === "popular" && "Popular Builds"}
              </h3>
              {buildspl.map((build) => (
                <Col sm={12} md={8} lg={6} xl={4} key={build._id}>
                  <BuildCard_v2 build={build} />
                </Col>
              ))}
            </Row>
          </Container>
        </>
      )}
      {buildsnw && criteria === "new" && (
        <>
          <Container className="justify-content-center my-0" style={style}>
            <Row>
              <h3 className="text-center">
                {criteria === "featured" && "Featured Builds"}
                {criteria === "new" && "Latest Builds"}
                {criteria === "popular" && "Popular Builds"}
              </h3>
              {buildsnw.map((build) => (
                <Col sm={12} md={8} lg={6} xl={4} key={build._id}>
                  <BuildCard_v2 build={build} />
                </Col>
              ))}

              <div className="text-center">
                <Button
                  className="mx-auto my-3 "
                  style={{ borderRadius: "5px" }}
                  variant="success"
                  size="lg"
                  onClick={() => {
                    setViewall(true);
                  }}
                >
                  View All Builds
                </Button>
              </div>
            </Row>
          </Container>
        </>
      )}
    </div>
  );
};

export default FeaturedBuildsSection;
