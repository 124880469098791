import { lazy, Suspense } from "react";
import "./App.css";
import Container from "react-bootstrap/Container";
import { Fragment } from "react";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import HomeScreen from "./Screens/HomeScreen";
import { Routes, Route , Navigate} from "react-router-dom";
import Loader from "./Components/Loader";
import {Helmet} from "react-helmet";


const AddModAndProduct = lazy(() => import("./Components/AddModAndProduct"));
const LoginScreen = lazy(() => import("./Screens/LoginScreen"));
const RegisterScreen = lazy(() => import("./Screens/RegisterScreen"));
const ProfileScreen = lazy(() => import("./Screens/ProfileScreen"));
const ModsScreen = lazy(() => import("./Screens/ModsScreen"));
const ProductListScreen = lazy(() => import("./Screens/ProductListScreen"));
const AllProductsByBuildScreen = lazy(() => import("./Screens/AllProductsByBuildScreen"));
const CreateBuildScreen = lazy(() => import("./Screens/CreateBuildScreen"));
const BuildsToApproveScreen = lazy(() => import("./Screens/BuildsToApproveScreen"));
const BuildToReviewCard = lazy(() => import("./Components/BuildToReviewCard"));
const BuildToApproveCard = lazy(() => import("./Components/BuildToApproveCard"));
const ProductToApproveCard = lazy(() => import("./Components/ProductToApproveCard"));
const SavedBuildsScreen = lazy(() => import("./Screens/SavedBuildsScreen"));
const UpdateBuildScreen = lazy(() => import("./Screens/UpdateBuildScreen"));
const ProductDealsScreen = lazy(() => import("./Screens/ProductDealsScreen"));
const SavedProductsScreen = lazy(()=>import("./Screens/SavedProductsScreen"));
const SavedProductsByModScreen = lazy(()=>import("./Screens/SavedProductsByModScreen"));
const BuildScreen = lazy(()=>import("./Screens/BuildScreen"));
const AddProductScreen = lazy(()=> import("./Screens/AddProductScreen"))
const UpdateProductScreen = lazy(()=> import("./Screens/UpdateProductScreen"));
const FollowedBuildsUpdates = lazy(() => import("./Screens/FollowedBuildsUpdates"));
const BuildsToFeatureScreen = lazy(() => import("./Screens/BuildsToFeatureScreen"));
const AddAFLScreen = lazy(() => import("./Screens/AddAFLScreen"));
const EditProductDealsScreen = lazy(() => import("./Screens/EditProductDealsScreen"));
const AboutOBScreen= lazy(()=>import("./Screens/AboutOBScreen"));
const ProductDetailsScreen = lazy(() => import("./Screens/ProductDetailsScreen"));
const UserControlsScreen = lazy(() => import("./Screens/UserControlsScreen"));
const ForgotPasswordScreen = lazy(() => import("./Screens/ForgotPasswordScreen"));





function App() {
  return (
    <Fragment>
      <>
        <Header />
        <Helmet  >
          <title>Overland Builds</title>
          <meta name="description" content="A collection of overland dedicated vehicles. Search, filter save and follow your favorite builds." />
          <meta name='keywords' content='overland, builds, overlanding, offroad, adventures, roof top tent, overland modification, rtt' />
        </Helmet>

        <main className="">
          <Container fluid style={{padding:'0'}} >
          <Suspense fallback={<div className="text-center my-3"><Loader/></div>}>
            <Routes>
              <Route path="/" exact element={<HomeScreen />} />
              <Route path="/login" element={<LoginScreen />} />
              <Route path="/savedbuilds" element={<SavedBuildsScreen />} />
              <Route path="/savedproducts" element={<SavedProductsScreen />} />
              <Route path="/productsafl" element={<AddAFLScreen />} />
              <Route path="/aboutus" element={<AboutOBScreen/>}/>
              <Route path="/forgot-password" element={<ForgotPasswordScreen/>}/>
              <Route path='/productstofeature' element={<EditProductDealsScreen />}/>
              <Route
                path="/savedproductsbymod/:modName"
                element={<SavedProductsByModScreen />}
              />
              <Route path="/tundra" element={<Navigate  to='/build/2'/>} />
              <Route path="/build/:id" element={<BuildScreen />} />
              <Route path="/addproduct/:id" element={<AddProductScreen />} />
              <Route path="/addproductrev/:id" element={<AddModAndProduct />} />
              <Route path="/mods/:id" element={<ModsScreen />} />
              <Route path="/usercontrols" element={<UserControlsScreen />} />
              <Route
                path="/followedbuildsupdates"
                element={<FollowedBuildsUpdates />}
              />
              <Route
                path="/products/:id/:bid"
                element={<ProductListScreen />}
              />
              <Route path="/updatebuild/:id" element={<UpdateBuildScreen />} />
              <Route
                path="/updateproduct/:id/:bid"
                element={<UpdateProductScreen />}
              />
              <Route
                path="/reviewproducts/:id"
                element={<AllProductsByBuildScreen />}
              />
              <Route
                path="/productdetails/:id/:bid"
                element={<ProductDetailsScreen />}
              />
              <Route path="/register" element={<RegisterScreen />} />
              <Route path="/profile" element={<ProfileScreen />} />
              
              <Route path="/productdeals" element={<ProductDealsScreen />} />
              
              <Route path="/createbuild/:uid" element={<CreateBuildScreen />} />
              <Route
                path="/buildstofeature/"
                element={<BuildsToFeatureScreen />}
              />
              <Route path="pendingapproval" element={<BuildsToApproveScreen />}>
                <Route
                  path="buildapproval/:bid"
                  element={<BuildToApproveCard />}
                />

                <Route
                  path="buildreview/:bid"
                  element={<BuildToReviewCard />}
                />
                <Route
                  path="productapproval/:pid"
                  element={<ProductToApproveCard />}
                />
                <Route
                  path="productreview/:pid"
                  element={<ProductToApproveCard />}
                />
                
              </Route>
              
            </Routes>
          </Suspense>
          </Container>
        </main>

        <Footer />
      </>
    </Fragment>
  );
}

export default App;
