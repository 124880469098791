export const carData = [
  {
    make: "Select Make",
    model: "Select Model",
  },

  {
    make: "Chevrolet",
    models: [
      "Select Model",
      "Colorado",
      "Silverado 1500",
      "Silverado 2500",
      "Silverado 3500",
      "Suburban",
      "Tahoe",
      "TrailBlazer",
    ],
  },
  {
    make: "Ford",
    models: [
      "Select Model",
      "Bronco",
      "E-series Van",
      "Excursion",
      "Expedition",
      "Explorer",
      "F150",
      "F250",
      "F350",
      "Ranger",
    ],
  },
  {
    make: "GMC",
    models: [
      "Select Model",
      "Canyon",
      "Sierra 1500",
      "Sierra 2500",
      "Sierra 3500",
      "Suburban",
      "Yukon",
    ],
  },
  {
    make: "Honda",
    models: ["Select Model", "Passport", "Pilot", "Ridgeline"],
  },
  {
    make: "Jeep",
    models: [
      "Select Model",
      "Cherokee",
      "Compass",
      "Grand Cherokee",
      "Patriot",
      "Renegade",
      "Commander",
      "Commanche",
      "Wrangler",
      "Grand Wagoneer",
      "Gladiator",
      "Liberty",
    ],
  },
  {
    make: "Land Rover",
    models: [
      "Select Model",
      "Defender",
      "Discovery",
      "Freelander",
      "Range Rover",
      "Range Rover Evoque",
      "Range Rover Sport",
      "LR2",
      "LR4",
      "LR3",
    ],
  },
  {
    make: "Lexus",
    models: ["Select Model", "GX 460", "GX 470", "LX 450", "LX 470", "LX 570"],
  },
  {
    make: "Mercedes-Benz",
    models: ["Select Model", "G-Class", "Sprinter"],
  },
  {
    make: "Nissan",
    models: ["Select Model", "Armada", "Frontier", "Titan", "Xterra"],
  },
  {
    make: "Mitubishi",
    models: ["Select Model", "Montero"],
  },
  {
    make: "Ram",
    models: ["Select Model", "1500", "2500", "3500"],
  },
  {
    make: "Subaru",
    models: ["Select Model", "Crosstrek", "Forester", "Outback"],
  },
  {
    make: "Toyota",
    models: [
      "Select Model",
      "4Runner",
      "FJ Cruiser",
      "Land Cruiser",
      "RAV4",
      "Sequoia",
      "Tacoma",
      "Tundra",
    ],
  },
  {
    make: "Volkswagen",
    models: ["Select Model", "Touareg", "Tiguan"],
  },
  {
    make: "Other",
    models: ["Select Model", "Other"],
  },
];
