import React, { useEffect, useState } from "react";
import Pagination from "react-bootstrap/Pagination";
import { useNavigate } from "react-router";
import { PRODUCT_UPDATE_RESET } from "../constants/productConstants";

const Paginate = (props) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

  useEffect(() => {
    //window.scrollTo(0, 350);
  }, [props.pages, props.page, currentPage, minPageNumberLimit, maxPageNumberLimit]);

  let keyword = "";
  if (props.keyword) {
    keyword = props.keyword.split("=")[1];
  } else {
    keyword = "";
  }
  let isAdmin = "";

  if (props.isAdmin) {
    isAdmin = props.isAdmin;
  } else {
    isAdmin = false;
  }

  let route = "";
  if (props.route) {
    route = props.route;
  } else {
    route = "";
  }

  //const items = [...Array(props.pages).keys()];

  const handleNextbtn = () => {
    setCurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
    navigate(
      `/${isAdmin ? "admin/" : ""}${route}?keyword=${keyword}&page=${
        currentPage + 1
      }`
    );
  };

  const handleFirstPage = () => {
    setCurrentPage(1);
    setmaxPageNumberLimit(5);
    setminPageNumberLimit(0);
    navigate(
      `/${isAdmin ? "admin/" : ""}${route}?keyword=${keyword}&page=1`
    );
  };

  const handleLastPage = () => {
    setCurrentPage(props.pages);
    setmaxPageNumberLimit(props.pages);
    if (props.pages - pageNumberLimit > 0) {
    setminPageNumberLimit(props.pages - 5);
    } else {
    setminPageNumberLimit(0);
    }
    navigate(
      `/${isAdmin ? "admin/" : ""}${route}?keyword=${keyword}&page=${
        props.pages
      }`
    );
  };


  const handlePrevbtn = () => {
    setCurrentPage(currentPage - 1);

    if ((currentPage - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
    if (currentPage === props.pages ) {
      setmaxPageNumberLimit(props.pages);
      setminPageNumberLimit(props.pages - 5);
    }
    navigate(
      `/${isAdmin ? "admin/" : ""}${route}?keyword=${keyword}&page=${
        currentPage - 1
      }`
    );
  };

  return (
    props.pages > 1 && (
      <Pagination>
        <Pagination.Item
          key={"start"}
          onClick={handleFirstPage}
          //active={currentPage === 1}
        >
          First Page
        </Pagination.Item>
        {currentPage > 1 && currentPage !== 1 && props.pages >5 && (
          <Pagination.Item
            key={"prev"}
            onClick={handlePrevbtn}
            disabled={currentPage === 1 ? true : false}
          >
            Previous
          </Pagination.Item>
        )}

        {[...Array(props.pages).keys()].map((number) => {
          if (number +1 < maxPageNumberLimit +1 && number +1  > minPageNumberLimit) {
            return (
              <Pagination.Item
                onClick={() => {
                  navigate(
                    `/${
                      isAdmin ? "admin/" : ""
                    }${route}?keyword=${keyword}&page=${number +1}`
                  );
                  setCurrentPage(number+1);
                }}
                key={number +1}
                active={props.page === number +1 ? true : false}
              >
                {number +1}
              </Pagination.Item>
            );
          } else {
            return null;
          }
        })}

        {currentPage > 1 && currentPage + 1 != props.pages && props.pages > 5 && (
          <Pagination.Item
            key={"next"}
            onClick={handleNextbtn}
            disabled={currentPage === props.pages - 1 ? true : false || currentPage === props.pages ? true : false}
          >
            Next
          </Pagination.Item>
        )}
        <Pagination.Item
          key={"end"}
          onClick={handleLastPage}
          disabled={currentPage === props.pages ? true : false}
          active={currentPage === props.pages ? true : false}
        >
          Last Page
        </Pagination.Item>
      </Pagination>
    )
  );
};

export default Paginate;
