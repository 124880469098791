export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';

export const USER_LOGOUT = 'USER_LOGOUT';

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL';

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST';
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS';
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL';
export const USER_DETAILS_RESET = 'USER_DETAILS_RESET';

export const USER_UPDATE_PROFILE_REQUEST = 'USER_UPDATE_PROFILE_REQUEST';
export const USER_UPDATE_PROFILE_SUCCESS = 'USER_UPDATE_PROFILE_SUCCESS';
export const USER_UPDATE_PROFILE_FAIL = 'USER_UPDATE_PROFILE_FAIL';
export const USER_UPDATE_PROFILE_RESET = 'USER_UPDATE_PROFILE_RESET';

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST';
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const USER_LIST_FAIL = 'USER_LIST_FAIL';
export const USER_LIST_RESET = 'USER_LIST_RESET';

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST';
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL';

export const USER_UPDATE_REQUEST = 'USER_UPDATE_PASSWORD_REQUEST';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_PASSWORD_SUCCESS';
export const USER_UPDATE_FAIL = 'USER_UPDATE_PASSWORD_FAIL';
export const USER_UPDATE_RESET = 'USER_UPDATE_PASSWORD_RESET';

export const USER_NOTIFICATIONS_REQUEST = 'USER_NOTIFICATIONS_REQUEST';
export const USER_NOTIFICATIONS_SUCCESS = 'USER_NOTIFICATIONS_SUCCESS';
export const USER_NOTIFICATIONS_FAIL = 'USER_NOTIFICATIONS_FAIL';
export const USER_NOTIFICATIONS_RESET = 'USER_NOTIFICATIONS_RESET';

export const CLEAR_USER_NOTIFICATIONS_REQUEST = 'CLEAR_USER_NOTIFICATIONS_REQUEST';
export const CLEAR_USER_NOTIFICATIONS_SUCCESS = 'CLEAR_USER_NOTIFICATIONS_SUCCESS';
export const CLEAR_USER_NOTIFICATIONS_FAIL = 'CLEAR_USER_NOTIFICATIONS_FAIL';
export const CLEAR_USER_NOTIFICATIONS_RESET = 'CLEAR_USER_NOTIFICATIONS_RESET';

export const EMAIL_PREFERENCES_REQUEST = 'EMAIL_PREFERENCES_REQUEST';
export const EMAIL_PREFERENCES_SUCCESS = 'EMAIL_PREFERENCES_SUCCESS';
export const EMAIL_PREFERENCES_FAIL = 'EMAIL_PREFERENCES_FAIL';

export const EMAIL_PREFERENCES_UPDATE_REQUEST = 'EMAIL_PREFERENCES_UPDATE_REQUEST';
export const EMAIL_PREFERENCES_UPDATE_SUCCESS = 'EMAIL_PREFERENCES_UPDATE_SUCCESS';
export const EMAIL_PREFERENCES_UPDATE_FAIL = 'EMAIL_PREFERENCES_UPDATE_FAIL';

export const CODE_VERIFICATION_REQUEST = 'CODE_VERIFICATION_REQUEST';
export const CODE_VERIFICATION_SUCCESS = 'CODE_VERIFICATION_SUCCESS';
export const CODE_VERIFICATION_FAIL = 'CODE_VERIFICATION_FAIL';
export const CODE_VERIFICATION_RESET = 'CODE_VERIFICATION_RESET';

export const PASSWORD_RESET_REQUEST = 'PASSWORD_RESET_REQUEST';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_FAIL = 'PASSWORD_RESET_FAIL';
export const PASSWORD_RESET_RESET = 'PASSWORD_RESET_RESET';







