import {
  BUILD_LIST_REQUEST,
  BUILD_LIST_SUCCESS,
  BUILD_LIST_FAIL,
  BUILD_DETAILS_SUCCESS,
  BUILD_DETAILS_REQUEST,
  BUILD_DETAILS_FAIL,
  BUILD_MY_BUILDS_REQUEST,
  BUILD_MY_BUILDS_SUCCESS,
  BUILD_MY_BUILDS_FAIL,
  BUILD_CREATE_REQUEST,
  BUILD_CREATE_SUCCESS,
  BUILD_CREATE_FAIL,
  BUILD_CREATE_RESET,
  BUILDS_TO_APPROVE_FAIL,
  BUILDS_TO_APPROVE_REQUEST,
  BUILDS_TO_APPROVE_SUCCESS,
  APPROVE_BUILD_REQUEST,
  APPROVE_BUILD_SUCCESS,
  APPROVE_BUILD_FAIL,
  REVIEW_BUILD_REQUEST,
  REVIEW_BUILD_SUCCESS,
  REVIEW_BUILD_FAIL,
  BUILD_REVIEW_LIST_REQUEST,
  BUILD_REVIEW_LIST_SUCCESS,
  BUILD_REVIEW_LIST_FAIL,
  BUILD_FILTER_REQUEST,
  BUILD_FILTER_SUCCESS,
  BUILD_FILTER_FAIL,
  BUILD_FILTER_RESET,
  SAVED_BUILDS_LIST_REQUEST,
  SAVED_BUILDS_LIST_SUCCESS,
  SAVED_BUILDS_LIST_FAIL,
  SAVED_BUILDS_LIST_RESET,
  GET_BADGE_INFO_REQUEST,
  GET_BADGE_INFO_SUCCESS,
  GET_BADGE_INFO_FAIL,
  BADGE_BUILD_REQUEST,
  BADGE_BUILD_SUCCESS,
  BADGE_BUILD_FAIL,
  BADGE_BUILD_RESET,
  FOLLOW_BUILD_REQUEST,
  FOLLOW_BUILD_SUCCESS,
  FOLLOW_BUILD_FAIL,
  FOLLOW_BUILD_RESET,
  SAVE_BUILD_FAIL,
  SAVE_BUILD_REQUEST,
  SAVE_BUILD_SUCCESS,
  SAVE_BUILD_RESET,
  BUILD_FOLLOWERS_LIST_REQUEST,
  BUILD_FOLLOWERS_LIST_SUCCESS,
  BUILD_FOLLOWERS_LIST_FAIL,
  BUILD_FOLLOWERS_LIST_RESET,
  UNSAVE_BUILD_FAIL,
  UNSAVE_BUILD_REQUEST,
  UNSAVE_BUILD_SUCCESS,
  UNSAVE_BUILD_RESET,
  UNFOLLOW_BUILD_FAIL,
  UNFOLLOW_BUILD_REQUEST,
  UNFOLLOW_BUILD_SUCCESS,
  UNFOLLOW_BUILD_RESET,
  BUILD_DELETE_REQUEST,
  BUILD_DELETE_SUCCESS,
  BUILD_DELETE_FAIL,
  BUILD_DELETE_RESET,
  BUILD_UPDATE_REQUEST,
  BUILD_UPDATE_SUCCESS,
  BUILD_UPDATE_FAIL,
  BUILD_UPDATE_RESET,
  FEATURE_BUILD_LIST_REQUEST,
  FEATURE_BUILD_LIST_SUCCESS,
  FEATURE_BUILD_LIST_FAIL,
  FEATURE_BUILD_LIST_RESET,
  MAKE_BUILD_FEATURE_REQUEST,
  MAKE_BUILD_FEATURE_SUCCESS,
  MAKE_BUILD_FEATURE_FAIL,
  MAKE_BUILD_FEATURE_RESET,
  LIST_BUILDS_BY_SECTION_REQUEST ,
  LIST_BUILDS_BY_SECTION_SUCCESS ,
  LIST_BUILDS_BY_SECTION_FAIL ,
  LIST_BUILDS_BY_SECTION_RESET ,
  BUILD_FEATURE_COUNT_REQUEST ,
  BUILD_FEATURE_COUNT_SUCCESS ,
  BUILD_FEATURE_COUNT_FAIL ,
  FOLLOWED_BUILDS_LIST_REQUEST,
  FOLLOWED_BUILDS_LIST_SUCCESS,
  FOLLOWED_BUILDS_LIST_FAIL,
  FOLLOWED_BUILDS_LIST_RESET,
} from "../constants/buildConstants";

export const buildListReducer = (state = { builds: [] }, action) => {
  switch (action.type) {
    case BUILD_LIST_REQUEST:
      return { loading: true, builds: [] };

    case BUILD_LIST_SUCCESS:
      return {
        loading: false,
        builds: action.payload.builds,
        page: action.payload.page,
        pages: action.payload.pages,
      };
    case BUILD_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const myBuildListReducer = (state = { myBuilds: [] }, action) => {
  switch (action.type) {
    case BUILD_MY_BUILDS_REQUEST:
      return { loading: true, myBuilds: [] };

    case BUILD_MY_BUILDS_SUCCESS:
      return {
        loading: false,
        myBuilds: action.payload,
      };
    case BUILD_MY_BUILDS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const buildDetailsReducer = (
  state = { build: { reviews: [] } },
  action
) => {
  switch (action.type) {
    case BUILD_DETAILS_REQUEST:
      return { loading: true, ...state };

    case BUILD_DETAILS_SUCCESS:
      return {
        loading: false,
        build: action.payload,
      };
    case BUILD_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const buildCreateReducer = (state = { build: {} }, action) => {
  switch (action.type) {
    case BUILD_CREATE_REQUEST:
      return { loading: true };
    case BUILD_CREATE_SUCCESS:
      return { loading: false, success: true, build: action.payload };
    case BUILD_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case BUILD_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const toApproveBuildListReducer = (state = { builds: [] }, action) => {
  switch (action.type) {
    case BUILDS_TO_APPROVE_REQUEST:
      return { loading: true, builds: [] };

    case BUILDS_TO_APPROVE_SUCCESS:
      return {
        loading: false,
        builds: action.payload,
      };
    case BUILDS_TO_APPROVE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const approveBuildReducer = (state = { build: {} }, action) => {
  switch (action.type) {
    case APPROVE_BUILD_REQUEST:
      return { loading: true };
    case APPROVE_BUILD_SUCCESS:
      return { loading: false, success: true, build: action.payload };
    case APPROVE_BUILD_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const buildReviewListReducer = (state = { builds: [] }, action) => {
  switch (action.type) {
    case BUILD_REVIEW_LIST_REQUEST:
      return { loading: true, builds: [] };

    case BUILD_REVIEW_LIST_SUCCESS:
      return {
        loading: false,
        builds: action.payload,
      };
    case BUILD_REVIEW_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const reviewBuildReducer = (state = { build: {} }, action) => {
  switch (action.type) {
    case REVIEW_BUILD_REQUEST:
      return { loading: true };
    case REVIEW_BUILD_SUCCESS:
      return { loading: false, success: true, buid: action.payload };
    case REVIEW_BUILD_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const filterBuildReducer = (state = { builds: [] }, action) => {
  switch (action.type) {
    case BUILD_FILTER_REQUEST:
      return { loading: true, builds: [] };

    case BUILD_FILTER_SUCCESS:
      return {
        loading: false,
        builds: action.payload,
      };
    case BUILD_FILTER_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case BUILD_FILTER_RESET:
      return {};
    default:
      return state;
  }
};

export const savedbuildsListReducer = (state = { builds: [] }, action) => {
  switch (action.type) {
    case SAVED_BUILDS_LIST_REQUEST:
      return { loading: true, builds: [] };

    case SAVED_BUILDS_LIST_SUCCESS:
      return {
        loading: false,
        builds: action.payload.builds,
        page: action.payload.page,
        pages: action.payload.pages,
      };
    case SAVED_BUILDS_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case SAVED_BUILDS_LIST_RESET:
      return {};
    default:
      return state;
  }
};

export const followedBuildsListReducer = (state = { builds: [] }, action) => {
  switch (action.type) {
    case FOLLOWED_BUILDS_LIST_REQUEST:
      return { loading: true, builds: [] };

    case FOLLOWED_BUILDS_LIST_SUCCESS:
      return {
        loading: false,
        builds: action.payload.builds,
        page: action.payload.page,
        pages: action.payload.pages,
      };
    case FOLLOWED_BUILDS_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case FOLLOWED_BUILDS_LIST_RESET:
      return {};
    default:
      return state;
  }
};


export const buildBadgeInfoReducer = (state = { badges: [] }, action) => {
  switch (action.type) {
    case GET_BADGE_INFO_REQUEST:
      return { loading: true, badges: [] };

    case GET_BADGE_INFO_SUCCESS:
      return {
        loading: false,
        badges: action.payload,
      };
    case GET_BADGE_INFO_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const badgeBuildReducer = (state = { badges: [] }, action) => {
  switch (action.type) {
    case BADGE_BUILD_REQUEST:
      return { loading: true, badges: [] };

    case BADGE_BUILD_SUCCESS:
      return {
        loading: false,
        badges: action.payload,
      };
    case BADGE_BUILD_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case BADGE_BUILD_RESET:
      return { badges: [] };
    default:
      return state;
  }
};

export const followBuildReducer = (state = { following: [] }, action) => {
  switch (action.type) {
    case FOLLOW_BUILD_REQUEST:
      return { loading: true, following: [] };

    case FOLLOW_BUILD_SUCCESS:
      return {
        loading: false,
        following: action.payload,
      };
    case FOLLOW_BUILD_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case FOLLOW_BUILD_RESET:
      return { following: [] };
    default:
      return state;
  }
};

export const saveBuildReducer = (state = { saved: [] }, action) => {
  switch (action.type) {
    case SAVE_BUILD_REQUEST:
      return { loading: true, saved: [] };

    case SAVE_BUILD_SUCCESS:
      return {
        loading: false,
        saved: action.payload,
      };
    case SAVE_BUILD_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case SAVE_BUILD_RESET:
      return { saved: [] };
    default:
      return state;
  }
};

export const buildFollowersListReducer = (
  state = { followers: [] },
  action
) => {
  switch (action.type) {
    case BUILD_FOLLOWERS_LIST_REQUEST:
      return { loading: true, followers: [] };

    case BUILD_FOLLOWERS_LIST_SUCCESS:
      return {
        loading: false,
        followers: action.payload,
      };
    case BUILD_FOLLOWERS_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case BUILD_FOLLOWERS_LIST_RESET:
      return {};
    default:
      return state;
  }
};

export const unfollowBuildReducer = (state = { unfollow: [] }, action) => {
  switch (action.type) {
    case UNFOLLOW_BUILD_REQUEST:
      return { loading: true, unfollow: [] };

    case UNFOLLOW_BUILD_SUCCESS:
      return {
        loading: false,
        unfollow: action.payload,
        success: true,
      };
    case UNFOLLOW_BUILD_FAIL:
      return {
        loading: false,
        error: action.payload,
        success: false,
      };
    case UNFOLLOW_BUILD_RESET:
      return { unfollow: [] };
    default:
      return state;
  }
};

export const unsaveBuildReducer = (state = { unsave: [] }, action) => {
  switch (action.type) {
    case UNSAVE_BUILD_REQUEST:
      return { loading: true, unsave: [] };

    case UNSAVE_BUILD_SUCCESS:
      return {
        loading: false,
        unsave: action.payload,
        success: true,
      };
    case UNSAVE_BUILD_FAIL:
      return {
        loading: false,
        error: action.payload,
        success: false,
      };
    case UNSAVE_BUILD_RESET:
      return { unsave: [] };
    default:
      return state;
  }
};

export const deleteBuildReducer = (state = { delete: [] }, action) => {
  switch (action.type) {
    case BUILD_DELETE_REQUEST:
      return { loading: true, delete: [] };

    case BUILD_DELETE_SUCCESS:
      return {
        loading: false,
        delete: action.payload,
        success: true,
      };
    case BUILD_DELETE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case BUILD_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const updateBuildReducer = (state = { build: {} }, action) => {
  switch (action.type) {
    case BUILD_UPDATE_REQUEST:
      return { loading: true };
    case BUILD_UPDATE_SUCCESS:
      return { loading: false, success: true, build: action.payload };
    case BUILD_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const featureBuildListReducer = (state = { builds: [] }, action) => {
  switch (action.type) {
    case FEATURE_BUILD_LIST_REQUEST:
      return { loading: true, builds: [] };

    case FEATURE_BUILD_LIST_SUCCESS:
      return {
        loading: false,
        builds: action.payload.builds,
        page: action.payload.page,
        pages: action.payload.pages,
      };
    case FEATURE_BUILD_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case FEATURE_BUILD_LIST_RESET:
      return {};
    default:
      return state;
  }
};


export const featureBuildReducer = (state = { featured: [] }, action) => {
  switch (action.type) {
    case MAKE_BUILD_FEATURE_REQUEST:
      return { loading: true, featured: [] };

    case MAKE_BUILD_FEATURE_SUCCESS:
      return {
        loading: false,
        featured: action.payload,
        success: true,

      };
    case MAKE_BUILD_FEATURE_FAIL:
      return {
        loading: false,
        error: action.payload,
        success: false,
      };
    case MAKE_BUILD_FEATURE_RESET:
      return { };
    default:
      return state;
  }
};

export const buildsBySectionReducer = (state = { builds: [] }, action) => {
  switch (action.type) {
    case LIST_BUILDS_BY_SECTION_REQUEST:
      return { loading: true, builds: [] };

    case LIST_BUILDS_BY_SECTION_SUCCESS:
      return {
        loading: false,
        buildsft: action.payload[0].featured,
        buildspl: action.payload[1].popular,
        buildsnw: action.payload[2].new,
        
      };
    case LIST_BUILDS_BY_SECTION_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const buildFeatureCountReducer = (state = { features: [] }, action) => {
  switch (action.type) {
    case BUILD_FEATURE_COUNT_REQUEST:
      return { loading: true, features: [] };

    case BUILD_FEATURE_COUNT_SUCCESS:
      return {
        loading: false,
        features: action.payload,
      };
    case BUILD_FEATURE_COUNT_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
