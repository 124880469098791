import {
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,
  ALL_PRODUCTS_BY_BUILD_REQUEST,
  ALL_PRODUCTS_BY_BUILD_SUCCESS,
  ALL_PRODUCTS_BY_BUILD_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  PRODUCT_DETAILS_RESET,
  PRODUCT_ADD_REQUEST,
  PRODUCT_ADD_SUCCESS,
  PRODUCT_ADD_FAIL,
  PRODUCTS_TO_APPROVE_REQUEST,
  PRODUCTS_TO_APPROVE_SUCCESS,
  PRODUCTS_TO_APPROVE_FAIL,
  APPROVE_PRODUCT_FAIL,
  APPROVE_PRODUCT_SUCCESS,
  APPROVE_PRODUCT_REQUEST,
  PRODUCT_REVIEW_LIST_FAIL,
  PRODUCT_REVIEW_LIST_SUCCESS,
  PRODUCT_REVIEW_LIST_REQUEST,
  PRODUCT_REVIEW_REQUEST,
  PRODUCT_REVIEW_SUCCESS,
  PRODUCT_REVIEW_FAIL,
  SAVE_PRODUCT_REQUEST,
  SAVE_PRODUCT_SUCCESS,
  SAVE_PRODUCT_FAIL,
  SAVE_PRODUCT_RESET,
  UNSAVE_PRODUCT_REQUEST,
  UNSAVE_PRODUCT_SUCCESS,
  UNSAVE_PRODUCT_FAIL,
  UNSAVE_PRODUCT_RESET,
  SAVED_PRODUCTS_LIST_REQUEST,
  SAVED_PRODUCTS_LIST_SUCCESS,
  SAVED_PRODUCTS_LIST_FAIL,
  SAVED_PRODUCTS_LIST_RESET,
  SAVED_PRODUCTS_BY_MOD_REQUEST,
  SAVED_PRODUCTS_BY_MOD_SUCCESS,
  SAVED_PRODUCTS_BY_MOD_FAIL,
  SAVED_PRODUCTS_BY_MOD_RESET,
  PRODUCT_UPDATE_REQUEST,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_UPDATE_FAIL,
  PRODUCT_UPDATE_RESET,
  PRODUCT_DELETE_REQUEST,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_DELETE_FAIL,
  PRODUCT_DELETE_RESET,
  PRODUCTS_MISSING_AFL_REQUEST,
  PRODUCTS_MISSING_AFL_SUCCESS,
  PRODUCTS_MISSING_AFL_FAIL,
  ADD_PRODUCT_AFL_REQUEST,
  ADD_PRODUCT_AFL_SUCCESS,
  ADD_PRODUCT_AFL_FAIL,
  ADD_PRODUCT_AFL_RESET,
  CREATE_PRODUCT_DEAL_REQUEST,
  CREATE_PRODUCT_DEAL_SUCCESS,
  CREATE_PRODUCT_DEAL_FAIL,
  PRODUCTS_DEAL_LIST_REQUEST,
  PRODUCTS_DEAL_LIST_SUCCESS,
  PRODUCTS_DEAL_LIST_FAIL,
  UPDATE_PRODUCT_DEAL_REQUEST,
  UPDATE_PRODUCT_DEAL_SUCCESS,
  UPDATE_PRODUCT_DEAL_FAIL,
  UPDATE_DEAL_REQUEST,
  UPDATE_DEAL_SUCCESS,
  UPDATE_DEAL_FAIL,
} from "../constants/productConstants";

export const productListReducer = (state = { products: [] }, action) => {
  switch (action.type) {
    case PRODUCT_LIST_REQUEST:
      return { loading: true, products: [] };

    case PRODUCT_LIST_SUCCESS:
      return {
        loading: false,
        products: action.payload,
      };
    case PRODUCT_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const allproductsByBuildReducer = (state = { products: [] }, action) => {
  switch (action.type) {
    case ALL_PRODUCTS_BY_BUILD_REQUEST:
      return { loading: true, products: [] };

    case ALL_PRODUCTS_BY_BUILD_SUCCESS:
      return {
        loading: false,
        products: action.payload.products,
        page: action.payload.page,
        pages: action.payload.pages,
      };
    case ALL_PRODUCTS_BY_BUILD_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const productDetailsReducer = (
  state = { product: { reviews: [] } },
  action
) => {
  switch (action.type) {
    case PRODUCT_DETAILS_REQUEST:
      return { loading: true, ...state };
    case PRODUCT_DETAILS_SUCCESS:
      return { loading: false, product: action.payload };
    case PRODUCT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_DETAILS_RESET:
      return {};
    default:
      return state;
  }
};

export const productCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_ADD_REQUEST:
      return { loading: true };

    case PRODUCT_ADD_SUCCESS:
      return {
        loading: false,
        success: true,
        product: action.payload,
      };
    case PRODUCT_ADD_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const productsToApproveListReducer = (
  state = { products: [] },
  action
) => {
  switch (action.type) {
    case PRODUCTS_TO_APPROVE_REQUEST:
      return { loading: true, products: [] };

    case PRODUCTS_TO_APPROVE_SUCCESS:
      return {
        loading: false,
        products: action.payload,
      };
    case PRODUCTS_TO_APPROVE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const approveProductReducer = (state = { product: {} }, action) => {
  switch (action.type) {
    case APPROVE_PRODUCT_REQUEST:
      return { loading: true };
    case APPROVE_PRODUCT_SUCCESS:
      return { loading: false, success: true, product: action.payload };
    case APPROVE_PRODUCT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const productsToReviewListReducer = (
  state = { products: [] },
  action
) => {
  switch (action.type) {
    case PRODUCT_REVIEW_LIST_REQUEST:
      return { loading: true, products: [] };

    case PRODUCT_REVIEW_LIST_SUCCESS:
      return {
        loading: false,
        products: action.payload,
      };
    case PRODUCT_REVIEW_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const reviewProductReducer = (state = { product: {} }, action) => {
  switch (action.type) {
    case PRODUCT_REVIEW_REQUEST:
      return { loading: true };
    case PRODUCT_REVIEW_SUCCESS:
      return { loading: false, success: true, product: action.payload };
    case PRODUCT_REVIEW_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const saveProductReducer = (state = { saved: [] }, action) => {
  switch (action.type) {
    case SAVE_PRODUCT_REQUEST:
      return { loading: true, saved: [] };

    case SAVE_PRODUCT_SUCCESS:
      return {
        loading: false,
        saved: action.payload,
      };
    case SAVE_PRODUCT_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case SAVE_PRODUCT_RESET:
      return { saved: [] };
    default:
      return state;
  }
};

export const unsaveProductReducer = (state = { unsave: [] }, action) => {
  switch (action.type) {
    case UNSAVE_PRODUCT_REQUEST:
      return { loading: true, unsave: [] };

    case UNSAVE_PRODUCT_SUCCESS:
      return {
        loading: false,
        unsave: action.payload,
        success: true,
      };
    case UNSAVE_PRODUCT_FAIL:
      return {
        loading: false,
        error: action.payload,
        success: false,
      };
    case UNSAVE_PRODUCT_RESET:
      return { unsave: [] };
    default:
      return state;
  }
};

export const savedProductsListReducer = (state = { products: [] }, action) => {
  switch (action.type) {
    case SAVED_PRODUCTS_LIST_REQUEST:
      return { loading: true, products: [] };

    case SAVED_PRODUCTS_LIST_SUCCESS:
      return {
        loading: false,
        products: action.payload.products,
        page: action.payload.page,
        pages: action.payload.pages,
      };
    case SAVED_PRODUCTS_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case SAVED_PRODUCTS_LIST_RESET:
      return {};
    default:
      return state;
  }
};

export const savedProductsByModReducer = (state = { products: [] }, action) => {
  switch (action.type) {
    case SAVED_PRODUCTS_BY_MOD_REQUEST:
      return { loading: true, products: [] };

    case SAVED_PRODUCTS_BY_MOD_SUCCESS:
      return {
        loading: false,
        products: action.payload.products,
        page: action.payload.page,
        pages: action.payload.pages,
      };
    case SAVED_PRODUCTS_BY_MOD_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case SAVED_PRODUCTS_BY_MOD_RESET:
      return {};
    default:
      return state;
  }
};

export const updateProductReducer = (state = { product: {} }, action) => {
  switch (action.type) {
    case PRODUCT_UPDATE_REQUEST:
      return { loading: true };

    case PRODUCT_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        product: action.payload,
      };
    case PRODUCT_UPDATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const deleteProductReducer = (state = { delete: [] }, action) => {
  switch (action.type) {
    case PRODUCT_DELETE_REQUEST:
      return { loading: true, delete: [] };

    case PRODUCT_DELETE_SUCCESS:
      return {
        loading: false,
        delete: action.payload,
        success: true,
      };
    case PRODUCT_DELETE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case PRODUCT_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const productsToAddAFLReducer = (state = { products: [] }, action) => {
  switch (action.type) {
    case PRODUCTS_MISSING_AFL_REQUEST:
      return { loading: true, products: [] };

    case PRODUCTS_MISSING_AFL_SUCCESS:
      return {
        loading: false,
        products: action.payload,
      };
    case PRODUCTS_MISSING_AFL_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const addProductAFLReducer = (state = { afl: [] }, action) => {
  switch (action.type) {
    case ADD_PRODUCT_AFL_REQUEST:
      return { loading: true, afl: [] };

    case ADD_PRODUCT_AFL_SUCCESS:
      return {
        loading: false,
        afl: action.payload,
        success: true,
      };
    case ADD_PRODUCT_AFL_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case ADD_PRODUCT_AFL_RESET:
      return {};

    default:
      return state;
  }
};

export const createDealReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_PRODUCT_DEAL_REQUEST:
      return { loading: true };

    case CREATE_PRODUCT_DEAL_SUCCESS:
      return {
        loading: false,
        success: true,
        deal: action.payload,
      };
    case CREATE_PRODUCT_DEAL_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const productDealsReducer = (state = { deals: [] }, action) => {
  switch (action.type) {
    case PRODUCTS_DEAL_LIST_REQUEST:
      return { loading: true, deals: [] };

    case PRODUCTS_DEAL_LIST_SUCCESS:
      return {
        loading: false,
        deals: action.payload.deals,
        page: action.payload.page,
        pages: action.payload.pages,
      };
    case PRODUCTS_DEAL_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const editDealReducer = (state = { deal: {} }, action) => {
  switch (action.type) {
    case UPDATE_PRODUCT_DEAL_REQUEST:
      return { loading: true };

    case UPDATE_PRODUCT_DEAL_SUCCESS:
      return {
        loading: false,
        success: true,
        deal: action.payload,
      };
    case UPDATE_PRODUCT_DEAL_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const updateDealReducer = (state = { deal: {} }, action) => {
  switch (action.type) {
    case UPDATE_DEAL_REQUEST:
      return { loading: true };

    case UPDATE_DEAL_SUCCESS:
      return {
        loading: false,
        success: true,
        deal: action.payload,
      };
    case UPDATE_DEAL_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
