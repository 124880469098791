export const PRODUCT_LIST_REQUEST = "PRODUCT_LIST_REQUEST";
export const PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS";
export const PRODUCT_LIST_FAIL = "PRODUCT_LIST_FAIL";

export const ALL_PRODUCTS_BY_BUILD_REQUEST = "ALL_PRODUCTS_BY_BUILD_REQUEST";
export const ALL_PRODUCTS_BY_BUILD_SUCCESS = "ALL_PRODUCTS_BY_BUILD_SUCCESS";
export const ALL_PRODUCTS_BY_BUILD_FAIL = "ALL_PRODUCTS_BY_BUILD_FAIL";

export const PRODUCT_DETAILS_REQUEST = "PRODUCT_DETAILS_REQUEST";
export const PRODUCT_DETAILS_SUCCESS = "PRODUCT_DETAILS_SUCCESS";
export const PRODUCT_DETAILS_FAIL = "PRODUCT_DETAILS_FAIL";
export const PRODUCT_DETAILS_RESET = "PRODUCT_DETAILS_RESET";

export const PRODUCT_ADD_REQUEST = "PRODUCT_ADD_REQUEST";
export const PRODUCT_ADD_SUCCESS = "PRODUCT_ADD_SUCCESS";
export const PRODUCT_ADD_FAIL = "PRODUCT_ADD_FAIL";

export const PRODUCTS_TO_APPROVE_REQUEST = "PRODUCTS_TO_APPROVE_REQUEST";
export const PRODUCTS_TO_APPROVE_SUCCESS = "PRODUCTS_TO_APPROVE_SUCCESS";
export const PRODUCTS_TO_APPROVE_FAIL = "PRODUCTS_TO_APPROVE_FAIL";

export const APPROVE_PRODUCT_REQUEST = 'APPROVE_PRODUCT_REQUEST';
export const APPROVE_PRODUCT_SUCCESS = 'APPROVE_PRODUCT_SUCCESS';
export const APPROVE_PRODUCT_FAIL = 'APPROVE_PRODUCT_FAIL';

export const PRODUCT_REVIEW_LIST_REQUEST = 'PRODUCT_REVIEW_LIST_REQUEST';
export const PRODUCT_REVIEW_LIST_SUCCESS = 'PRODUCT_REVIEW_LIST_SUCCESS';
export const PRODUCT_REVIEW_LIST_FAIL = 'PRODUCT_REVIEW_LIST_FAIL';

export const PRODUCT_REVIEW_REQUEST = 'PRODUCT_REVIEW_REQUEST';
export const PRODUCT_REVIEW_SUCCESS = 'PRODUCT_REVIEW_SUCCESS';
export const PRODUCT_REVIEW_FAIL = 'PRODUCT_REVIEW_FAIL';

export const SAVE_PRODUCT_REQUEST = 'SAVE_PRODUCT_REQUEST';
export const SAVE_PRODUCT_SUCCESS = 'SAVE_PRODUCT_SUCCESS';
export const SAVE_PRODUCT_FAIL = 'SAVE_PRODUCT_FAIL';
export const SAVE_PRODUCT_RESET = 'SAVE_PRODUCT_RESET';

export const UNSAVE_PRODUCT_REQUEST = 'UNSAVE_PRODUCT_REQUEST';
export const UNSAVE_PRODUCT_SUCCESS = 'UNSAVE_PRODUCT_SUCCESS';
export const UNSAVE_PRODUCT_FAIL = 'UNSAVE_PRODUCT_FAIL';
export const UNSAVE_PRODUCT_RESET = 'UNSAVE_PRODUCT_RESET';

export const SAVED_PRODUCTS_LIST_REQUEST = 'SAVED_PRODUCTS_LIST_REQUEST';
export const SAVED_PRODUCTS_LIST_SUCCESS = 'SAVED_PRODUCTS_LIST_SUCCESS';
export const SAVED_PRODUCTS_LIST_FAIL = 'SAVED_PRODUCTS_LIST_FAIL';
export const SAVED_PRODUCTS_LIST_RESET = 'SAVED_PRODUCTS_LIST_RESET';

export const SAVED_PRODUCTS_BY_MOD_REQUEST = 'SAVED_PRODUCTS_BY_MOD_REQUEST';
export const SAVED_PRODUCTS_BY_MOD_SUCCESS = 'SAVED_PRODUCTS_BY_MOD_SUCCESS';
export const SAVED_PRODUCTS_BY_MOD_FAIL = 'SAVED_PRODUCTS_BY_MOD_FAIL';
export const SAVED_PRODUCTS_BY_MOD_RESET = 'SAVED_PRODUCTS_BY_MOD_RESET';

export const PRODUCT_UPDATE_REQUEST = 'PRODUCT_UPDATE_REQUEST';
export const PRODUCT_UPDATE_SUCCESS = 'PRODUCT_UPDATE_SUCCESS';
export const PRODUCT_UPDATE_FAIL = 'PRODUCT_UPDATE_FAIL';
export const PRODUCT_UPDATE_RESET = 'PRODUCT_UPDATE_RESET';

export const PRODUCT_DELETE_REQUEST = 'PRODUCT_DELETE_REQUEST';
export const PRODUCT_DELETE_SUCCESS = 'PRODUCT_DELETE_SUCCESS';
export const PRODUCT_DELETE_FAIL = 'PRODUCT_DELETE_FAIL';
export const PRODUCT_DELETE_RESET = 'PRODUCT_DELETE_RESET';

export const PRODUCTS_MISSING_AFL_REQUEST = 'PRODUCTS_MISSING_AFL_REQUEST';
export const PRODUCTS_MISSING_AFL_SUCCESS = 'PRODUCTS_MISSING_AFL_SUCCESS';
export const PRODUCTS_MISSING_AFL_FAIL = 'PRODUCTS_MISSING_AFL_FAIL';

export const ADD_PRODUCT_AFL_REQUEST = 'ADD_PRODUCT_AFL_REQUEST';
export const ADD_PRODUCT_AFL_SUCCESS = 'ADD_PRODUCT_AFL_SUCCESS';
export const ADD_PRODUCT_AFL_FAIL = 'ADD_PRODUCT_AFL_FAIL';
export const ADD_PRODUCT_AFL_RESET = 'ADD_PRODUCT_AFL_RESET';



export const CREATE_PRODUCT_DEAL_REQUEST = 'CREATE_PRODUCT_DEAL_REQUEST';
export const CREATE_PRODUCT_DEAL_SUCCESS = 'CREATE_PRODUCT_DEAL_SUCCESS';
export const CREATE_PRODUCT_DEAL_FAIL = 'CREATE_PRODUCT_DEAL_FAIL';

export const PRODUCTS_DEAL_LIST_REQUEST = ' PRODUCTS_DEAL_LIST_REQUEST';
export const PRODUCTS_DEAL_LIST_SUCCESS = 'PRODUCTS_DEAL_LIST_SUCCESS';
export const PRODUCTS_DEAL_LIST_FAIL = 'PRODUCTS_DEAL_LIST_FAIL'

export const UPDATE_PRODUCT_DEAL_REQUEST ='UPDATE_PRODUCT_DEAL_REQUEST';
export const UPDATE_PRODUCT_DEAL_SUCCESS = 'UPDATE_PRODUCT_DEAL_SUCCESS';
export const UPDATE_PRODUCT_DEAL_FAIL = 'UPDATE_PRODUCT_DEAL_FAIL';


export const UPDATE_DEAL_REQUEST ='UPDATE_DEAL_REQUEST';
export const UPDATE_DEAL_SUCCESS = 'UPDATE_DEAL_SUCCESS';
export const UPDATE_DEAL_FAIL = 'UPDATE_DEAL_FAIL';



