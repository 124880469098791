import {
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,
  ALL_PRODUCTS_BY_BUILD_REQUEST,
  ALL_PRODUCTS_BY_BUILD_SUCCESS,
  ALL_PRODUCTS_BY_BUILD_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  PRODUCT_DETAILS_RESET,
  PRODUCT_ADD_REQUEST,
  PRODUCT_ADD_SUCCESS,
  PRODUCT_ADD_FAIL,
  PRODUCTS_TO_APPROVE_REQUEST,
  PRODUCTS_TO_APPROVE_SUCCESS,
  PRODUCTS_TO_APPROVE_FAIL,
  APPROVE_PRODUCT_FAIL,
  APPROVE_PRODUCT_SUCCESS,
  APPROVE_PRODUCT_REQUEST,
  PRODUCT_REVIEW_LIST_FAIL,
  PRODUCT_REVIEW_LIST_SUCCESS,
  PRODUCT_REVIEW_LIST_REQUEST,
  PRODUCT_REVIEW_REQUEST,
  PRODUCT_REVIEW_SUCCESS,
  PRODUCT_REVIEW_FAIL,
  SAVE_PRODUCT_REQUEST,
  SAVE_PRODUCT_SUCCESS,
  SAVE_PRODUCT_FAIL,
  SAVE_PRODUCT_RESET,
  UNSAVE_PRODUCT_REQUEST,
  UNSAVE_PRODUCT_SUCCESS,
  UNSAVE_PRODUCT_FAIL,
  UNSAVE_PRODUCT_RESET,
  SAVED_PRODUCTS_LIST_REQUEST,
  SAVED_PRODUCTS_LIST_SUCCESS,
  SAVED_PRODUCTS_LIST_FAIL,
  SAVED_PRODUCTS_BY_MOD_REQUEST,
  SAVED_PRODUCTS_BY_MOD_SUCCESS,
  SAVED_PRODUCTS_BY_MOD_FAIL,
  PRODUCT_UPDATE_REQUEST,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_UPDATE_FAIL,
  PRODUCT_DELETE_REQUEST,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_DELETE_FAIL,
  PRODUCT_DELETE_RESET,
  PRODUCTS_MISSING_AFL_REQUEST,
  PRODUCTS_MISSING_AFL_SUCCESS,
  PRODUCTS_MISSING_AFL_FAIL,
  ADD_PRODUCT_AFL_REQUEST,
  ADD_PRODUCT_AFL_SUCCESS,
  ADD_PRODUCT_AFL_FAIL,
  CREATE_PRODUCT_DEAL_REQUEST,
  CREATE_PRODUCT_DEAL_SUCCESS,
  CREATE_PRODUCT_DEAL_FAIL,
  PRODUCTS_DEAL_LIST_REQUEST,
  PRODUCTS_DEAL_LIST_SUCCESS,
  PRODUCTS_DEAL_LIST_FAIL,
  UPDATE_PRODUCT_DEAL_REQUEST,
  UPDATE_PRODUCT_DEAL_SUCCESS,
  UPDATE_PRODUCT_DEAL_FAIL,
  UPDATE_DEAL_REQUEST,
  UPDATE_DEAL_SUCCESS,
  UPDATE_DEAL_FAIL,
} from "../constants/productConstants";
import axios from "axios";

export const listProductsByBuildAndMod = (id, bid) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_LIST_REQUEST });

    const { data } = await axios.get(`https://obbackend.herokuapp.com/api/products/build/${id}/${bid}`);

    dispatch({ type: PRODUCT_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PRODUCT_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const listAllProductsByBuild = (id, keyword="") => async (dispatch) => {
  try {
    dispatch({ type: ALL_PRODUCTS_BY_BUILD_REQUEST });

    const { data } = await axios.get(`https://obbackend.herokuapp.com/api/products/all/${id}/${keyword}`);

    dispatch({ type: ALL_PRODUCTS_BY_BUILD_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: ALL_PRODUCTS_BY_BUILD_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const listProductDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_DETAILS_REQUEST });
    const { data } = await axios.get(`https://obbackend.herokuapp.com/api/products/productdetails/${id}`);
    dispatch({ type: PRODUCT_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PRODUCT_DETAILS_FAIL,
      payload: error.response && error.message,
    });
  }
};

export const createProduct = (product) => async (dispatch, getState) => {
  try {
    dispatch({ type: PRODUCT_ADD_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post("https://obbackend.herokuapp.com/api/products/create/", product, config);

    dispatch({ type: PRODUCT_ADD_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PRODUCT_ADD_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const listProductsToApprove = () => async (dispatch, getState) => {
  try {
    dispatch({ type: PRODUCTS_TO_APPROVE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`https://obbackend.herokuapp.com/api/admin/productstoapprove/`, config);

    dispatch({ type: PRODUCTS_TO_APPROVE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PRODUCTS_TO_APPROVE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const approveProductAction = (product) => async (dispatch, getState) => {
  try {
    dispatch({
      type: APPROVE_PRODUCT_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `https://obbackend.herokuapp.com/api/admin/approveproduct/${product._id}/`,
      product,
      config
    );

    dispatch({
      type: APPROVE_PRODUCT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: APPROVE_PRODUCT_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const listProductsToReview = () => async (dispatch, getState) => {
  try {
    dispatch({ type: PRODUCT_REVIEW_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`https://obbackend.herokuapp.com/api/admin/productsunderreview/`, config);

    dispatch({ type: PRODUCT_REVIEW_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PRODUCT_REVIEW_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const reviewProductAction = (product_id) => async (dispatch) => {
  try {
    dispatch({
      type: PRODUCT_REVIEW_REQUEST,
    });
    const data = product_id;

    dispatch({
      type: PRODUCT_REVIEW_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_REVIEW_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const saveProductAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SAVE_PRODUCT_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`https://obbackend.herokuapp.com/api/products/saveproduct/${id}/`, config);

    dispatch({ type: SAVE_PRODUCT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: SAVE_PRODUCT_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const saveReset = () => (dispatch) => {
  dispatch({ type: SAVE_PRODUCT_RESET });
};

export const unsaveProductAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: UNSAVE_PRODUCT_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `https://obbackend.herokuapp.com/api/products/unsaveproduct/${id}/`,
      config
    );

    dispatch({ type: UNSAVE_PRODUCT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: UNSAVE_PRODUCT_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const unsaveReset = () => (dispatch) => {
  dispatch({ type: UNSAVE_PRODUCT_RESET });
};

export const productDetailsReset = () => (dispatch) => {
  dispatch({ type: PRODUCT_DETAILS_RESET });
};

export const listSavedProducts = (keyword="") => async (dispatch, getState) => {
  try {
    dispatch({ type: SAVED_PRODUCTS_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`https://obbackend.herokuapp.com/api/products/getsavedproducts/${keyword}`, config);

    dispatch({ type: SAVED_PRODUCTS_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: SAVED_PRODUCTS_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const listSavedProductsByMod = (name, keyword="") => async (dispatch, getState) => {
  try {
    dispatch({ type: SAVED_PRODUCTS_BY_MOD_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `https://obbackend.herokuapp.com/api/products/getsavedproductsbymod/${keyword}`,
      name,
      config
    );

    dispatch({ type: SAVED_PRODUCTS_BY_MOD_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: SAVED_PRODUCTS_BY_MOD_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const updateProductdAction = (product) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `https://obbackend.herokuapp.com/api/products/updateproduct/${product._id}/`,
      product,
      config
    );

    dispatch({
      type: PRODUCT_UPDATE_SUCCESS,
      payload: data,
      success: true,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_UPDATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const deleteProductAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: PRODUCT_DELETE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.delete(
      `https://obbackend.herokuapp.com/api/products/deleteproduct/${id}/`,
      config
    );

    dispatch({ type: PRODUCT_DELETE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PRODUCT_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const deleteProductReset = () => (dispatch) => {
  dispatch({ type: PRODUCT_DELETE_RESET });
};

export const listProductsMissingAFL =
  (keyword = "") =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: PRODUCTS_MISSING_AFL_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `https://obbackend.herokuapp.com/api/admin/productsmissingafl/${keyword}`,
        config
      );

      dispatch({ type: PRODUCTS_MISSING_AFL_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: PRODUCTS_MISSING_AFL_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const addProductAFLAction = (product) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_PRODUCT_AFL_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `https://obbackend.herokuapp.com/api/admin/addProductAFL/${product._id}/`,
      product,
      config
    );

    dispatch({
      type: ADD_PRODUCT_AFL_SUCCESS,
      payload: data,
      success: true,
    });
  } catch (error) {
    dispatch({
      type: ADD_PRODUCT_AFL_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const createDealAction = (deal) => async (dispatch, getState) => {
  try {
    dispatch({ type: CREATE_PRODUCT_DEAL_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post("https://obbackend.herokuapp.com/api/admin/createdeal/", deal, config);

    dispatch({ type: CREATE_PRODUCT_DEAL_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: CREATE_PRODUCT_DEAL_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const listDealsAction = (keyword="") => async (dispatch) => {
  try {
    dispatch({ type: PRODUCTS_DEAL_LIST_REQUEST });

    const { data } = await axios.get(`https://obbackend.herokuapp.com/api/admin/productdeals/${keyword}`);

    dispatch({ type: PRODUCTS_DEAL_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PRODUCTS_DEAL_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const editDealAction = (deal) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UPDATE_PRODUCT_DEAL_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `https://obbackend.herokuapp.com/api/admin/editproductdeal/${deal._id}/${deal.type}`,
      deal.inactivate,
      config
    );

    dispatch({
      type: UPDATE_PRODUCT_DEAL_SUCCESS,
      payload: data,
      success: true,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_PRODUCT_DEAL_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const updateDealAction = (deal) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UPDATE_DEAL_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `https://obbackend.herokuapp.com/api/admin/updatedeal/${deal._id}/`,
      deal,
      config
    );

    dispatch({
      type: UPDATE_DEAL_SUCCESS,
      payload: data,
      success: true,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_DEAL_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
