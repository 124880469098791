import { Link } from 'react-router-dom';
import React from "react";
import { Row, Col, Container } from "react-bootstrap";


const Footer = () => {
  return (
    <div>
      <footer >
        <Container>
          <Row>
            <Col className="text-center py-3 my-1" style={{
              //position: 'fixed',
              bottom: '0',

            }}> Copyright © 2022 - POWERED BY: Lander Central <p style={{fontSize:'0.8rem'}}>This website uses affiliate links to direct you to external pages. 
              <Link to="/aboutus" className="my-3 mx-1">
        More info
      </Link>
      </p> </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
};

export default Footer;
