import {
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,
    USER_LOGOUT,
    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
    USER_REGISTER_FAIL,
    USER_DETAILS_FAIL,
    USER_DETAILS_REQUEST,
    USER_DETAILS_SUCCESS,
    USER_DETAILS_RESET,
    USER_UPDATE_PROFILE_REQUEST,
    USER_UPDATE_PROFILE_SUCCESS,
    USER_UPDATE_PROFILE_FAIL,
    USER_UPDATE_PROFILE_RESET,
    USER_LIST_FAIL,
    USER_LIST_REQUEST,
    USER_LIST_SUCCESS,
    USER_LIST_RESET,
    USER_DELETE_REQUEST,
    USER_DELETE_SUCCESS,
    USER_DELETE_FAIL,
    USER_UPDATE_REQUEST,
    USER_UPDATE_SUCCESS,
    USER_UPDATE_FAIL,
    USER_NOTIFICATIONS_REQUEST,
    USER_NOTIFICATIONS_SUCCESS,
    USER_NOTIFICATIONS_FAIL,
    USER_NOTIFICATIONS_RESET,
    CLEAR_USER_NOTIFICATIONS_REQUEST,
    CLEAR_USER_NOTIFICATIONS_SUCCESS,
    CLEAR_USER_NOTIFICATIONS_FAIL,
    CLEAR_USER_NOTIFICATIONS_RESET,
    EMAIL_PREFERENCES_REQUEST,
    EMAIL_PREFERENCES_SUCCESS,
    EMAIL_PREFERENCES_FAIL,
    EMAIL_PREFERENCES_UPDATE_REQUEST,
    EMAIL_PREFERENCES_UPDATE_SUCCESS,
    EMAIL_PREFERENCES_UPDATE_FAIL,
    CODE_VERIFICATION_REQUEST,
    CODE_VERIFICATION_SUCCESS,
    CODE_VERIFICATION_FAIL,
    PASSWORD_RESET_REQUEST,
    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_FAIL,
    CODE_VERIFICATION_RESET,
    PASSWORD_RESET_RESET,
  } from "../constants/userConstants";
  
  export const userLoginReducer = (state = {}, action) => {
    switch (action.type) {
      case USER_LOGIN_REQUEST:
        return { loading: true };
      case USER_LOGIN_SUCCESS:
        return { loading: false, userInfo: action.payload };
      case USER_LOGIN_FAIL:
        return { loading: false, error: action.payload };
      case USER_LOGOUT:
        return {};
      default:
        return state;
    }
  };
  
  export const userRegisterReducer = (state = {}, action) => {
    switch (action.type) {
      case USER_REGISTER_REQUEST:
        return { loading: true };
      case USER_REGISTER_SUCCESS:
        return { loading: false, userInfo: action.payload };
      case USER_REGISTER_FAIL:
        return { loading: false, error: action.payload };
      case USER_LOGOUT:
        return {};
      default:
        return state;
    }
  };
  
  export const userDetailsReducer = (state = { user: {} }, action) => {
    switch (action.type) {
      case USER_DETAILS_REQUEST:
        return { ...state, loading: true };
      case USER_DETAILS_SUCCESS:
        return { loading: false, user: action.payload };
      case USER_DETAILS_FAIL:
        return { loading: false, error: action.payload };
      case USER_DETAILS_RESET:
        return { user: {} };
      default:
        return state;
    }
  };
  
  export const userUpdateProfileReducer = (state = {}, action) => {
    switch (action.type) {
      case USER_UPDATE_PROFILE_REQUEST:
        return { loading: true };
      case USER_UPDATE_PROFILE_SUCCESS:
        return { loading: false, success: true, userInfo: action.payload };
      case USER_UPDATE_PROFILE_FAIL:
        return { loading: false, error: action.payload };
      case USER_UPDATE_PROFILE_RESET:
        return {};
      default:
        return state;
    }
  };
  
  export const userListReducer = (state = { users: [] }, action) => {
    switch (action.type) {
      case USER_LIST_REQUEST:
        return { loading: true };
      case USER_LIST_SUCCESS:
        return {
          loading: false,
          users: action.payload.users,
          page: action.payload.page,
          pages: action.payload.pages,
        };
      case USER_LIST_FAIL:
        return { loading: false, error: action.payload };
      case USER_LIST_RESET:
        return { users: [] };
      default:
        return state;
    }
  };
  
  export const userDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case USER_DELETE_REQUEST:
        return { loading: true };
      case USER_DELETE_SUCCESS:
        return { loading: false, success: true };
      case USER_DELETE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const userUpdateReducer = (state = { user: {} }, action) => {
    switch (action.type) {
      case USER_UPDATE_REQUEST:
        return { loading: true };
      case USER_UPDATE_SUCCESS:
        return { loading: false, success: true };
      case USER_UPDATE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

  export const userNotificationsReducer = (state = {notifications:[]}, action) => {
    switch (action.type) {
      case USER_NOTIFICATIONS_REQUEST:
        return { loading: true };
      case USER_NOTIFICATIONS_SUCCESS:
        return { loading: false, notifications: action.payload };
      case USER_NOTIFICATIONS_FAIL:
        return { loading: false, error: action.payload };
      case USER_NOTIFICATIONS_RESET:
        return { };
      default:
        return state;
    }
  }

  export const clearUserNotificationsReducer = (state = {}, action) => {
    switch (action.type) {
      case CLEAR_USER_NOTIFICATIONS_REQUEST:
        return { loading: true };
      case CLEAR_USER_NOTIFICATIONS_SUCCESS:
        return { loading: false, success: true };
      case CLEAR_USER_NOTIFICATIONS_FAIL:
        return { loading: false, error: action.payload };
      case CLEAR_USER_NOTIFICATIONS_RESET:
        return { };
      default:
        return state;
    }
  }

  export const emailPreferencesReducer = (state = {prefereces:[]}, action) => {
    switch (action.type) {
      case EMAIL_PREFERENCES_REQUEST:
        return { loading: true };
      case EMAIL_PREFERENCES_SUCCESS:
        return { loading: false, preferences: action.payload };
      case EMAIL_PREFERENCES_FAIL:
        return { loading: false, error: action.payload };

      default:
        return state;
    }
  }
  
  export const emailPreferencesUpdateReducer = (state = {}, action) => {
    switch (action.type) {
      case EMAIL_PREFERENCES_UPDATE_REQUEST:
        return { loading: true };
      case EMAIL_PREFERENCES_UPDATE_SUCCESS:
        return { loading: false, success: true };
      case EMAIL_PREFERENCES_UPDATE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  }

  export const resetPasswordCodeRequest = (state = {res:{}}, action) => {
    switch (action.type) {
      case CODE_VERIFICATION_REQUEST:
        return { loading: true };
      case CODE_VERIFICATION_SUCCESS:
        return { loading: false, success: true, res: action.payload };
      case CODE_VERIFICATION_FAIL:
        return { loading: false, error: action.payload };
      case CODE_VERIFICATION_RESET:
        return {};
      default:
        return state;
    }
  }

  export const resetPasswordRequest = (state = {res:{}}, action) => {
    switch (action.type) {
      case PASSWORD_RESET_REQUEST:
        return { loading: true };
      case PASSWORD_RESET_SUCCESS:
        return { loading: false, success: true, res: action.payload };
      case PASSWORD_RESET_FAIL:
        return { loading: false, error: action.payload };
      case PASSWORD_RESET_RESET:
        return {};
      default:
        return state;
    }
  }