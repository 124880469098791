import React, { useCallback } from "react";
import { Card, Button, Container, Row, Col } from "react-bootstrap";
import Loader from "./Loader";
import Message from "./Message";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const DealCard = (props) => {
  const deals = props.deals;
  const loading = props.dealsLoading;
  const error = props.dealsError;

  const MyImage = useCallback(
    ({ image }) => (
      <div>
        <LazyLoadImage
          effect="blur"
          height={160}
          src={image} // use normal <img> attributes as props
          width={240}
          style={{ borderRadius: "5px" }}
          padding="2px"
        />
        {/* <span>{image.caption}</span> */}
      </div>
    ),
    []
  );

  return (
    <Container>
      {loading && <Loader />}
      {error && <Message variant="danger">{error}</Message>}
      {deals && deals.length > 0 && (
        <Row className="d-flex align-items-stretch">
          <h3 className="my-3 text-center">Product Deals</h3>
          {deals.map((deal) => (
            <Col
              sm={12}
              md={6}
              lg={4}
              xl={3}
              key={deal._id}
              className="lg-4 mb-3 d-flex align-items-stretch"
            >
              <Card
                style={{
                  minWidth: "270px",
                  backgroundColor: "whitesmoke",
                  border: "0px",
                  borderRadius: "5px",
                }}
                className="my-3 mx-4"
              >
                {/* <Card.Img variant="top" src={deal.mainImage} style={{borderRadius:'5px'}}/> */}
                <Card.Body>
                  <div className="text-center">
                    <MyImage image={deal.mainImage} />
                  </div>
                </Card.Body>
                <Card.Body className="text-center">
                  <Card.Title>{deal.vendor}</Card.Title>
                  <Card.Text>{deal.description}</Card.Text>
                  <a href={deal.af_link} target="_blank" rel="noreferrer">
                    <Button variant="primary">Check it out!</Button>
                  </a>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </Container>
  );
};

export default DealCard;
