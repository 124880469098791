import {
  BUILD_LIST_REQUEST,
  BUILD_LIST_SUCCESS,
  BUILD_LIST_FAIL,
  BUILD_DETAILS_SUCCESS,
  BUILD_DETAILS_REQUEST,
  BUILD_DETAILS_FAIL,
  BUILD_MY_BUILDS_REQUEST,
  BUILD_MY_BUILDS_SUCCESS,
  BUILD_MY_BUILDS_FAIL,
  BUILD_CREATE_REQUEST,
  BUILD_CREATE_SUCCESS,
  BUILD_CREATE_FAIL,
  BUILDS_TO_APPROVE_FAIL,
  BUILDS_TO_APPROVE_REQUEST,
  BUILDS_TO_APPROVE_SUCCESS,
  APPROVE_BUILD_REQUEST,
  APPROVE_BUILD_SUCCESS,
  APPROVE_BUILD_FAIL,
  REVIEW_BUILD_REQUEST,
  REVIEW_BUILD_SUCCESS,
  REVIEW_BUILD_FAIL,
  BUILD_REVIEW_LIST_REQUEST,
  BUILD_REVIEW_LIST_SUCCESS,
  BUILD_REVIEW_LIST_FAIL,
  BUILD_FILTER_REQUEST,
  BUILD_FILTER_SUCCESS,
  BUILD_FILTER_FAIL,
  BUILD_FILTER_RESET,
  SAVED_BUILDS_LIST_REQUEST,
  SAVED_BUILDS_LIST_SUCCESS,
  SAVED_BUILDS_LIST_FAIL,
  GET_BADGE_INFO_REQUEST,
  GET_BADGE_INFO_SUCCESS,
  GET_BADGE_INFO_FAIL,
  BADGE_BUILD_REQUEST,
  BADGE_BUILD_SUCCESS,
  BADGE_BUILD_FAIL,
  BADGE_BUILD_RESET,
  FOLLOW_BUILD_REQUEST,
  FOLLOW_BUILD_SUCCESS,
  FOLLOW_BUILD_FAIL,
  FOLLOW_BUILD_RESET,
  SAVE_BUILD_FAIL,
  SAVE_BUILD_REQUEST,
  SAVE_BUILD_SUCCESS,
  SAVE_BUILD_RESET,
  BUILD_FOLLOWERS_LIST_REQUEST,
  BUILD_FOLLOWERS_LIST_SUCCESS,
  BUILD_FOLLOWERS_LIST_FAIL,
  UNSAVE_BUILD_FAIL,
  UNSAVE_BUILD_REQUEST,
  UNSAVE_BUILD_SUCCESS,
  UNSAVE_BUILD_RESET,
  UNFOLLOW_BUILD_FAIL,
  UNFOLLOW_BUILD_REQUEST,
  UNFOLLOW_BUILD_SUCCESS,
  UNFOLLOW_BUILD_RESET,
  BUILD_DELETE_REQUEST,
  BUILD_DELETE_SUCCESS,
  BUILD_DELETE_FAIL,
  BUILD_DELETE_RESET,
  BUILD_UPDATE_REQUEST,
  BUILD_UPDATE_SUCCESS,
  BUILD_UPDATE_FAIL,
  FEATURE_BUILD_LIST_REQUEST,
  FEATURE_BUILD_LIST_SUCCESS,
  FEATURE_BUILD_LIST_FAIL,
  MAKE_BUILD_FEATURE_REQUEST,
  MAKE_BUILD_FEATURE_SUCCESS,
  MAKE_BUILD_FEATURE_FAIL,
  LIST_BUILDS_BY_SECTION_REQUEST,
  LIST_BUILDS_BY_SECTION_SUCCESS,
  LIST_BUILDS_BY_SECTION_FAIL,
  BUILD_FEATURE_COUNT_REQUEST ,
  BUILD_FEATURE_COUNT_SUCCESS ,
  BUILD_FEATURE_COUNT_FAIL ,
  FOLLOWED_BUILDS_LIST_REQUEST,
  FOLLOWED_BUILDS_LIST_SUCCESS,
  FOLLOWED_BUILDS_LIST_FAIL,
  FOLLOWED_BUILDS_LIST_RESET,
  EMAIL_PREFERENCES_REQUEST,
    EMAIL_PREFERENCES_SUCCESS,
    EMAIL_PREFERENCES_FAIL,
    EMAIL_PREFERENCES_UPDATE_REQUEST,
    EMAIL_PREFERENCES_UPDATE_SUCCESS,
    EMAIL_PREFERENCES_UPDATE_FAIL,
  
} from "../constants/buildConstants";
import axios from "axios";

export const listbuilds =
  (keyword = "") =>
  async (dispatch) => {
    try {
      dispatch({ type: BUILD_LIST_REQUEST });

      const { data } = await axios.get(`https://obbackend.herokuapp.com/api/builds/${keyword}`);


      dispatch({ type: BUILD_LIST_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: BUILD_LIST_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const listMybuilds = () => async (dispatch, getState) => {
  try {
    dispatch({ type: BUILD_MY_BUILDS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get("https://obbackend.herokuapp.com/api/builds/mybuilds/", config);

    dispatch({ type: BUILD_MY_BUILDS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: BUILD_MY_BUILDS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const listbuildDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: BUILD_DETAILS_REQUEST });

    const { data } = await axios.get(`https://obbackend.herokuapp.com/api/builds/${id}`);

    dispatch({ type: BUILD_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: BUILD_DETAILS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const createBuild = (build) => async (dispatch, getState) => {
  try {
    dispatch({
      type: BUILD_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(`https://obbackend.herokuapp.com/api/builds/create/`, build, config);

    dispatch({
      type: BUILD_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BUILD_CREATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const listbuildstoapprove = () => async (dispatch, getState) => {
  try {
    dispatch({ type: BUILDS_TO_APPROVE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get("https://obbackend.herokuapp.com/api/admin/buildstoapprove/", config);

    dispatch({ type: BUILDS_TO_APPROVE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: BUILDS_TO_APPROVE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const approveBuildAction = (build) => async (dispatch, getState) => {
  try {
    dispatch({
      type: APPROVE_BUILD_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `https://obbackend.herokuapp.com/api/admin/approvebuild/${build._id}/`,
      build,
      config
    );

    dispatch({
      type: APPROVE_BUILD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: APPROVE_BUILD_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const listbuildstoreview = () => async (dispatch, getState) => {
  try {
    dispatch({ type: BUILD_REVIEW_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get("https://obbackend.herokuapp.com/api/admin/buildsunderreview/", config);

    dispatch({ type: BUILD_REVIEW_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: BUILD_REVIEW_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const reviewBuildAction = (build_id) => async (dispatch) => {
  try {
    dispatch({
      type: REVIEW_BUILD_REQUEST,
    });
    const data = build_id;

    dispatch({
      type: REVIEW_BUILD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: REVIEW_BUILD_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const listFilteredBuilds = (criteria) => async (dispatch) => {
  try {
    dispatch({ type: BUILD_FILTER_REQUEST });

    const { data } = await axios.post("https://obbackend.herokuapp.com/api/builds/rangefilter/", criteria);

    dispatch({ type: BUILD_FILTER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: BUILD_FILTER_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const searchFilterReset = () => (dispatch) => {
  dispatch({ type: BUILD_FILTER_RESET });
};

export const listSavedBuilds = (keyword="") => async (dispatch, getState) => {
  try {
    dispatch({ type: SAVED_BUILDS_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`https://obbackend.herokuapp.com/api/builds/mysavedbuilds/${keyword}`, config);

    dispatch({ type: SAVED_BUILDS_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: SAVED_BUILDS_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const listFollowedBuilds = (keyword="") => async (dispatch, getState) => {
  try {
    dispatch({ type: FOLLOWED_BUILDS_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`https://obbackend.herokuapp.com/api/builds/followedbuilds/${keyword}`, config);

    dispatch({ type: FOLLOWED_BUILDS_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: FOLLOWED_BUILDS_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};


export const getBadgeInfo = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_BADGE_INFO_REQUEST });

    const { data } = await axios.get(`https://obbackend.herokuapp.com/api/builds/getbuildbadges/${id}`);

    dispatch({ type: GET_BADGE_INFO_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_BADGE_INFO_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const badgeBuildAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: BADGE_BUILD_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`https://obbackend.herokuapp.com/api/builds/badgebuild/${id}`, config);

    dispatch({ type: BADGE_BUILD_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: BADGE_BUILD_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const badgeReset = () => (dispatch) => {
  dispatch({ type: BADGE_BUILD_RESET });
};

export const followBuildAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: FOLLOW_BUILD_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`https://obbackend.herokuapp.com/api/builds/followbuild/${id}`, config);

    dispatch({ type: FOLLOW_BUILD_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: FOLLOW_BUILD_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const followReset = () => (dispatch) => {
  dispatch({ type: FOLLOW_BUILD_RESET });
};

export const saveBuildAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SAVE_BUILD_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`https://obbackend.herokuapp.com/api/builds/savebuild/${id}`, config);

    dispatch({ type: SAVE_BUILD_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: SAVE_BUILD_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const saveReset = () => (dispatch) => {
  dispatch({ type: SAVE_BUILD_RESET });
};

export const listBuildFollowers = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: BUILD_FOLLOWERS_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `https://obbackend.herokuapp.com/api/builds/getbuildfollowers/${id}`,
      config
    );

    dispatch({ type: BUILD_FOLLOWERS_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: BUILD_FOLLOWERS_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const unfollowBuildAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: UNFOLLOW_BUILD_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`https://obbackend.herokuapp.com/api/builds/unfollowbuild/${id}`, config);

    dispatch({ type: UNFOLLOW_BUILD_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: UNFOLLOW_BUILD_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const unfollowReset = () => (dispatch) => {
  dispatch({ type: UNFOLLOW_BUILD_RESET });
};

export const unsaveBuildAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: UNSAVE_BUILD_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`https://obbackend.herokuapp.com/api/builds/unsavebuild/${id}`, config);

    dispatch({ type: UNSAVE_BUILD_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: UNSAVE_BUILD_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const unsaveReset = () => (dispatch) => {
  dispatch({ type: UNSAVE_BUILD_RESET });
};

export const deleteBuildAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: BUILD_DELETE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.delete(
      `https://obbackend.herokuapp.com/api/builds/deletebuild/${id}`,
      config
    );

    dispatch({ type: BUILD_DELETE_SUCCESS, payload: data });
    dispatch({ type: BADGE_BUILD_RESET });
  } catch (error) {
    dispatch({
      type: BUILD_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const deleteBuildReset = () => (dispatch) => {
  dispatch({ type: BUILD_DELETE_RESET });
};

export const updateBuildAction = (build) => async (dispatch, getState) => {
  try {
    dispatch({
      type: BUILD_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `https://obbackend.herokuapp.com/api/builds/updatebuild/${build._id}`,
      build,
      config
    );

    dispatch({
      type: BUILD_UPDATE_SUCCESS,
      payload: data,
      success: true,
    });
  } catch (error) {
    dispatch({
      type: BUILD_UPDATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const listBuildsToFeatureAction =
  (keyword = "", sortby = "") =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: FEATURE_BUILD_LIST_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
     
      const { data } = await axios.get(
        `https://obbackend.herokuapp.com/api/admin/listbuildstofeature/${sortby}${keyword}`,
        config
      );

      dispatch({ type: FEATURE_BUILD_LIST_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: FEATURE_BUILD_LIST_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const featureBuildAction = (buildId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: MAKE_BUILD_FEATURE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `https://obbackend.herokuapp.com/api/admin/featurebuild/${buildId}`,

      config
    );

    dispatch({
      type: MAKE_BUILD_FEATURE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MAKE_BUILD_FEATURE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const listBuildsBySection =() => async (dispatch) => {
    try {
      dispatch({ type:  LIST_BUILDS_BY_SECTION_REQUEST });

      const config = {
        headers: {
          "Content-Type": "application/json",
          
        },
      };

      const { data } = await axios.get(`https://obbackend.herokuapp.com/api/builds/buildsbysection/`, config);

      dispatch({ type:  LIST_BUILDS_BY_SECTION_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type:   LIST_BUILDS_BY_SECTION_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

  export const getFeatureCount = (id) => async (dispatch) => {
    try {
      dispatch({ type: BUILD_FEATURE_COUNT_REQUEST });
  
      const { data } = await axios.get(`https://obbackend.herokuapp.com/api/builds/featurecount/${id}`);
  
      dispatch({ type: BUILD_FEATURE_COUNT_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: BUILD_FEATURE_COUNT_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };
