export const MODS_REQUEST = 'MODS_REQUEST'
export const MODS_REQUEST_SUCCESS = 'MODS_REQUEST_SUCCESS'
export const MODS_REQUEST_FAIL = 'MODS_REQUEST_FAIL'

export const MOD_CREATE_REQUEST = 'MOD_CREATE_REQUEST'
export const MOD_CREATE_REQUEST_SUCCESS = 'MOD_CREATE_REQUEST_SUCCESS'
export const MOD_CREATE_REQUEST_FAIL = 'MOD_CREATE_REQUEST_FAIL'
export const MOD_CREATE_RESET = 'MOD_CREATE_RESET'

export const MOD_DELETE_REQUEST = 'MOD_DELETE_REQUEST'
export const MOD_DELETE_SUCCESS = 'MOD_DELETE_SUCCESS'
export const MOD_DELETE_FAIL = 'MOD_DELETE_FAIL'
export const MOD_DELETE_RESET = 'MOD_DELETE_RESET'

