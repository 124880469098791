import React, { useEffect, useState, useCallback } from "react";
import {
  Navbar,
  Nav,
  NavDropdown,
  Image,
  Button,
  Row,
  Container,
  Alert,
  NavItem,
  Badge,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../actions/userActions";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  getNotificationsAction,
  clearNotificationAction,
} from "../actions/userActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faEnvelopeOpen,
  faDoorClosed,
  faNewspaper,
  faMoneyBillWave,
  faInfo,
} from "@fortawesome/free-solid-svg-icons";
// import { LazyLoadImage } from 'react-lazy-load-image-component';
// import 'react-lazy-load-image-component/src/effects/blur.css';

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const MyImage = useCallback(({ image }) => (
  //   <div>
  //     <LazyLoadImage
  //       effect="blur"
  //       width='auto'
  //       src={image} // use normal <img> attributes as props
  //       height='auto'
  //       style={{ borderRadius: "5px" }}
  //       padding="2px"
        
  //     />
  //     {/* <span>{image.caption}</span> */}
  //   </div>
  // ), []);

  const [show, setShow] = useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userNotifications = useSelector((state) => state.userNotifications);
  const { notifications } = userNotifications;

  const clearNotificationsHandler = () => {
    dispatch(clearNotificationAction("basic"));
    setTimeout(() => {
      dispatch(getNotificationsAction());
    }, 1000);
  };

  let newBadges = [];
  let newSaves = [];
  let newFollowers = [];
  let updatesFollowedBuilds = [];
  let totalNotifications = [];
  let myBuildFeatures = [];

  notifications &&
    notifications.length > 0 &&
    newBadges.push(notifications.filter((e) => e.notification_type === 1));
  notifications &&
    notifications.length > 0 &&
    newSaves.push(notifications.filter((e) => e.notification_type === 3));
  notifications &&
    notifications.length > 0 &&
    newFollowers.push(notifications.filter((e) => e.notification_type === 2));
  notifications &&
    notifications.length > 0 &&
    updatesFollowedBuilds.push(
      notifications.filter((e) => e.notification_type === 4)
    );
  notifications &&
    notifications.length > 0 &&
    totalNotifications.push(
      notifications
        .filter((e) => e.notification_type !== 4)
        .filter((e) => e.read !== true)
    );
  notifications &&
    notifications.length > 0 &&
    myBuildFeatures.push(
      notifications.filter((e) => e.notification_type === 5 && e.read === false)
    );

  const logoutHandler = () => {
    dispatch(logout());
    navigate("/");
  };

  const createABuildHandler = () => {
    if (userInfo) {
      navigate(`/createbuild/${userInfo._id}`);
    } else {
      setShow(true);
    }
  };

  useEffect(() => {
    if (userInfo) {
      dispatch(getNotificationsAction());
    }
  }, [userInfo]);

  const followedBuildUpdatesHandler = () => {
    navigate(`/followedbuildsupdates/`);
  };

  return (
    <header>
      <Navbar bg="light" expand="lg" collapseOnSelect>
        <Container fluid>
          <LinkContainer to="/">
            <Navbar.Brand className="my-2">Overland Builds</Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav
              className="me-auto"
              //  my-2 my-lg-0"
              // style={{ maxHeight: "100px" }}
              // navbarScroll
            >
              {userInfo ? (
                <>
                  <NavDropdown
                    id="basic-nav-dropdown"
                    title={<><i className="fas fa-compass mx-2"></i>MY BASE CAMP</>}
                    menuVariant="light"
                  >
                    <NavDropdown.Item>
                    <LinkContainer to="/profile" style={{color:'gray'}} as='div'>
                      <Nav.Link>
                        <i className="fas fa-garage"  ></i><i></i>Garage & Profile
                      </Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/savedbuilds" style={{color:'gray'}} as='div'>
                      <Nav.Link>
                        <i className="fas fa-star strong"></i> Followed Builds
                      </Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/savedproducts" style={{color:'gray'}} as='div'>
                      <Nav.Link>
                        <i className="fas fa-star"></i> Saved Mods
                      </Nav.Link>
                    </LinkContainer>
                    </NavDropdown.Item>
                  </NavDropdown>

                  <Nav.Item
                    as="span"
                    style={{
                      marginLeft: "1rem",
                      marginTop: "0.5rem",
                      inlineSize: "auto",
                    }}
                  >
                    <Badge as="div" bg="success" pill>
                      {totalNotifications &&
                        totalNotifications.length > 0 &&
                        totalNotifications[0].length > 0 &&
                        totalNotifications[0].length}
                    </Badge>
                    <FontAwesomeIcon icon={faBell} className="mx-1" />
                  </Nav.Item>

                  <NavDropdown
                    id="nav-dropdown-dark-example"
                    title="NOTIFICATIONS"
                    menuVariant="light"
                  >
                    <Nav.Link as="div" disabled>
                      {myBuildFeatures &&
                        myBuildFeatures.length > 0 &&
                        myBuildFeatures[0].length > 0 && (
                          <NavDropdown.Item as="div" style={{ color: "blue" }}>
                            <strong>Your Build Got Featured! 🏆</strong>
                          </NavDropdown.Item>
                        )}
                      <NavDropdown.Item className="" >
                        Badges Recieved
                        <Badge
                          bg="warning"
                          className=" mx-4 text-right"
                          align="end"
                        >
                          {newBadges &&
                            newBadges.length > 0 &&
                            newBadges[0].length > 0 &&
                            newBadges[0].length}
                        </Badge>
                      </NavDropdown.Item>
                    </Nav.Link>
                    <Nav.Link as="div" disabled>
                      <NavDropdown.Item>
                        New Followers{" "}
                        <Badge bg="warning" className="mx-4">
                          {newFollowers &&
                            newFollowers.length > 0 &&
                            newFollowers[0].length > 0 &&
                            newFollowers[0].length}
                        </Badge>
                      </NavDropdown.Item>
                    </Nav.Link>
                    {/* <Nav.Link as="div" disabled>
                      <NavDropdown.Item className="fill">
                        New Build Saves
                        <Badge
                          bg="warning"
                          className=" mx-4 text-right"
                          align="end"
                        >
                          {newSaves &&
                            newSaves.length > 0 &&
                            newSaves[0].length > 0 &&
                            newSaves[0].length}
                        </Badge>
                      </NavDropdown.Item>
                    </Nav.Link> */}

                    <Nav.Link as="div" className="mt-1">
                      <NavDropdown.Divider />
                      <NavDropdown.Item className="text-center">
                        <Button
                          variant="outline-danger"
                          onClick={clearNotificationsHandler}
                        >
                          Clear Notifications
                        </Button>
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <Nav.Link as="div">
                        <NavDropdown.Item className="text-center" onClick={followedBuildUpdatesHandler}>
                          <Button variant="outline-primary">
                        <Badge as="div" bg="info" pill>
                      {updatesFollowedBuilds &&
                        updatesFollowedBuilds.length > 0 &&
                        updatesFollowedBuilds[0].length > 0 &&
                        updatesFollowedBuilds[0].length}
                    </Badge> {" "}Build Updates
                          {/* <FontAwesomeIcon
                            icon={faNewspaper}
                            className="mx-2"
                            color="black"
                          /> */}
                          </Button>
                        </NavDropdown.Item>
                        
                      
                    </Nav.Link>
                    </Nav.Link>
                  </NavDropdown>

                  <Nav.Link as="div"></Nav.Link>

                  {userInfo.isAdmin && (
                    <NavDropdown
                      title="ADMIN LINKS"
                      id="navbarScrollingDropdown"
                    >
                      <LinkContainer to="/pendingapproval">
                        <NavDropdown.Item>Approval Page</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/buildstofeature">
                        <NavDropdown.Item>Builds to Feature</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/productsafl">
                        <NavDropdown.Item>Products w/o AFL</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/productstofeature">
                        <NavDropdown.Item>Push Product Deals</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/usercontrols">
                        <NavDropdown.Item>User Controls</NavDropdown.Item>
                      </LinkContainer>
                    </NavDropdown>
                  )}
                </>
              ) : (
                <LinkContainer to="/login">
                  <Nav.Link>
                    <i className="fas fa-user"></i>LOGIN
                  </Nav.Link>
                </LinkContainer>
              )}
              <LinkContainer to="/productdeals">
                <Nav.Link>
                  <FontAwesomeIcon icon={faMoneyBillWave} className="mx-1" />
                  DEALS
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to="/aboutus">
                <Nav.Link>
                  <FontAwesomeIcon icon={faInfo} className="mx-2" />
                  ABOUT
                </Nav.Link>
              </LinkContainer>
            </Nav>
            <Button
              onClick={createABuildHandler}
              className="px-4 ml-auto mx-2 my-2"
              variant="warning"
              style={{ borderRadius: "3px" }}
            >
              CREATE A BUILD
            </Button>

            {userInfo && (
              <Nav.Item
                align="right"
                as="div"
                style={{ marginLeft: "1rem", marginTop: "0.5rem" }}
              >
                <FontAwesomeIcon icon={faDoorClosed} className="mx-2" />
                <Button
                  variant="outline-danger"
                  size="sm"
                  onClick={logoutHandler}
                  className="my-2"
                >
                  LOGOUT
                </Button>
              </Nav.Item>
            )}

            {/* <Form className="d-flex mx-2 my-2">
              <FormControl
                type="search"
                placeholder="Search"
                className="me-2"
                aria-label="Search"
              />
              <Button variant="outline-success">Search</Button>
            </Form> */}
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Alert
        show={show}
        variant="warning"
        onClose={() => setShow(false)}
        dismissible
      >
        <Alert.Heading>
          Oh snap! You need to login to create a build!
        </Alert.Heading>
        <p>
          You can login or register <Link to="/login">here. </Link>
        </p>
      </Alert>
      <Row className="overflow">
       
        <Image src="/images/banner.jpg" fluid />
      </Row>
    </header>
  );
};

export default Header;
